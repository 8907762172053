<template>
    <div class="container">


        <span class="texto-fundo is-hidden-touch">LOGIN</span>
        <span class="texto-fundo2 is-hidden-touch">LOGIN</span>

        <br>
        <br>

        <div class="columns is-multiline is-centered">
            <div class="column is-12">
                <div class="level-item">
                    <br>
                    <span class="boas_vindas is-size-4-touch">Olá, Seja bem-vindo ao</span>
                </div>

                <template v-if="!this.$isMobile()">

                </template>
            </div>

            <div :class="`column is-5-desktop is-12-touch`">
                <div class=" box p-6  form-box">

                    <div class="mb-3" style="display: flex; justify-content: center;">

                        <img src="../assets/logo.png">

                    </div>
                    <form @submit.prevent="submitForm">

                        <div class="field">
                            <label class="form-label">Usuário</label>
                            <div class="control has-icons-left">
                                <input type="text" name="text" placeholder='' required class="input" v-model="username">
                                <span class="icon is-left"><font-awesome-icon icon="user" /></span>
                            </div>
                        </div>

                        <div class="field">
                            <label class="form-label">Senha</label>
                            <div class="control has-icons-left ">
                                <input type="password" name="password1" required class="input" v-model="password">
                                <span class="icon is-left"><font-awesome-icon icon="lock" /></span>
                            </div>
                        </div>
                        <div class="level-right">
                            <a href="https://api.reservai.app/password_reset"><span class="form-label">
                                    Esqueci minha
                                    senha</span></a>
                        </div>
                        <br>
                        <div class="notification is-danger is-light" v-if="errors.length">
                            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                        </div>

                        <div class="level-item">
                            <div class="field is-centered">
                                <div class="control">
                                    <button class="button is-success" :class="{ 'is-loading': this.carregando }"
                                        id="btn-entrar">Entrar</button>
                                </div>
                            </div>
                        </div>
                        <br>


                    </form>

                </div>
            </div>

            <div class="column is-narrow is-hidden-touch">
                <img class="image" id="mao" src="../assets/mao.png">

            </div>


            <div class="column is-12 pb-0">
                <div class="is-hidden-touch">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>

                </div>
                <div class="level-item">
                    <img class="image" src="../assets/power2.png">
                </div>

            </div>

        </div>


    </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast'

export default {
    name: "LogIn",
    data() {
        return {
            username: '',
            password: '',
            is_superuser: false,
            errors: [],
            error: true,
            time:
            {
                id: '',
                nome: '',
                is_activated: false,
                agreed_to_terms: false,
                id_created: '',
            },
            user: {
                id: '',
                nome: '',
            },
            token: '',
            carregando: false


        }
    },
    mounted() {
        if (this.$store.state.isAuthenticated) {
            this.$router.back()
        }
    },
    methods: {
        async submitForm() {

            this.carregando = true
            this.errors = []

            axios.defaults.headers.common['Authorization'] = ''
            localStorage.removeItem('token')
            const formData = {
                username: this.username,
                password: this.password
            }

            await axios
                .post('/api/v1/token/login/', formData)

                .then(response => {
                    this.token = response.data.auth_token
                    axios.defaults.headers.common['Authorization'] = 'Token ' + this.token
                    // console.log('aqui')
                })
                .catch(error => {
                    if (error.response) {
                        this.error = true
                        // var erro = ''
                        // for (const property in error.response.data) {

                        // }
                        this.errors.push('Usuário ou Senha incorreto(s)')
                        // toast({
                        //     message: 'Usuário ou senha incorretos!\n',
                        //     type: 'is-danger is-light',
                        //     dimissible: true,
                        //     pauseOnHover: true,
                        //     durations: 2000,
                        //     position: 'top-center',
                        // })
                    } else {
                        this.error = true
                        this.errors.push('Algo deu errado. Tente novamente!')
                    }
                })
            if (this.errors.length == 0) {

                await axios
                    .get('/api/v1/users/me')
                    .then(response => {
                        // console.log('aqui2')
                        this.user.nome = response.data.username
                        this.user.id = response.data.id

                    })
                    .catch(error => {

                        console.log(error)

                    })

                await axios.get('/api/v1/is_superuser/').then(response => {
                    // console.log('aqui3')
                    this.is_superuser = response.data.is_superuser


                })
                    .catch(error => {

                        console.log(error)

                    })

                await axios
                    .get('/api/v1/teams/get_my_team/')
                    .then(response => {
                        // console.log('aqui4')
                        //console.log(response.data)
                        this.time.nome = response.data.name
                        this.time.id = response.data.id
                        this.time.is_activated = response.data.is_activated
                        this.time.agreed_to_terms = response.data.agreed_to_terms
                        this.time.id_created = response.data.created_by.id
                    })
                    .catch(error => {
                        console.log(error)
                    })
                this.$store.commit('setToken', this.token) // Chama os métodos guardados no Mutations do index.js

                localStorage.setItem('token', this.token)

                this.$store.commit('setTeam', {
                    'id': this.time.id, 'name': this.time.nome, 'is_activated': this.time.is_activated,
                    'agreed_to_terms': this.time.agreed_to_terms
                })

                this.$store.commit('setUser', { 'id': this.user.id, 'username': this.user.nome })
                this.$store.commit('setIsSuperUser', this.is_superuser)

                localStorage.setItem('username', this.user.nome)
                localStorage.setItem('userid', this.user.id)
                localStorage.setItem('isSuperUser', this.is_superuser)

                localStorage.setItem('team_is_activated', this.time.is_activated)
                localStorage.setItem('team_agreed_to_terms', this.time.agreed_to_terms)

                if (this.time.is_activated) {
                    this.$store.commit('setIsLoading', true)

                    // console.log('aqui5')
                    const request = { time: this.time.nome }
                    await axios
                        .post('/api/v1/contatos/get_contatos_atualizados/', request)
                        .then(response => {

                            this.$store.commit('setIsLoading', false)
                            this.$router.push('/dashboard/')
                        })
                        .catch(error => {
                            console.log(error)
                        })

                } else if (!this.time.agreed_to_terms && this.user.id == this.time.id_created) {
                    this.$router.push('/termos-condicoes')
                } else {
                    this.errors.push(`O time ${this.time.nome} está desativado!`)
                    axios.defaults.headers.common['Authorization'] = ''
                    localStorage.removeItem('token')
                    localStorage.removeItem('token')
                    localStorage.removeItem('username')
                    localStorage.removeItem('userid')
                    localStorage.removeItem('team_name')
                    localStorage.removeItem('team_id')
                    localStorage.removeItem('isSuperUser')
                    localStorage.removeItem('team_is_activated')
                    localStorage.removeItem('team_agreed_to_terms')
                    this.$store.commit('removeToken') // Chama os métodos guardados no Mutations do index.js
                }



            }

            this.error = true
            this.username = ''
            this.password = ''
            this.carregando = false

        }
    }
}

</script>

<style>
@import '../assets/styles/login.scss'
</style>