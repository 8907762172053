<template>
    <div class="container">
        <div class="columns is-centered is-multiline">

            <div class="column is-12  pb-0">
                <div class="box">
                    <div class="columns is-vcentered is-centered">
                        <div class="column pr-0">
                            <div class="buttons">
                                <router-link :to="{ name: 'Contatos' }" class="button is-dark is-rounded"><font-awesome-icon
                                        icon="arrow-left" /></router-link>
                            </div>
                        </div>

                        <div class="column is-narrow pl-0">
                            <div class="level-item">
                                <figure class="image is-64x64" style="text-align:center;">
                                    <img class=" is-rounded" :src="`${!contato.profile_url == '' ? contato.profile_url :
                                        getImgPath('default')}`" alt="Foto de Perfil">
                                </figure>
                            </div>
                        </div>
                        <div class="column is-narrow">
                            <h1 class="title has-text-centered"> {{ contato.nome }}</h1>
                        </div>
                        <div class="column">
                            <div class="level-item">
                                <span v-if="this.contato"
                                    :class="`tag is-${getStatusColor(contato.status)} is-large is-rounded`">{{
                                        contato.status
                                    }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <router-link :to="{ name: 'EditLead', params: contato.id }" class="button is-light">Edit</router-link> -->

                </div>
            </div>
            <div class="column is-6 pb-0">
                <div class="box">
                    <h1 class="title has-text-centered	">Dados</h1>
                    <hr>

                    <div class="columns is-multiline">
                        <div class="column is-narrow">
                            <nav class="level is-mobile">
                                <div class="level-left">
                                    <div class="level-item  pb-0 ">
                                        <figure class="image is-24x24">
                                            <img :alt="`Logo do WhatsApp`" :src="this.getImgPath('Whatsapp')"
                                                class='is-rounded'>
                                        </figure>
                                    </div>
                                    <div class="level-item is-narrow pl-0 pb-0">
                                        <template v-if="contato.whatsapp != null">
                                            <a :href="`https://web.whatsapp.com/send?phone=${contato.whatsapp}`"
                                                target="_blank">
                                                <p class="is-dark is-size-5">{{ contato.whatsapp
                                                }}</p>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <span class="is-size-5">Não informado</span>
                                        </template>
                                    </div>
                                </div>
                            </nav>
                            <p class="pb-1 is-size-5"><strong>Email: </strong> {{ contato.email === null ? "Não informado" :
                                contato.email }}</p>

                            <p class="pb-1 is-size-5"><strong>Membro: </strong> {{ contato.membro_atribuido?.username !==
                                undefined ? contato.membro_atribuido?.username : "Não atribuído"
                            }}
                            </p>
                            <p class=" is-size-5"><strong>Última Interação: </strong>
                                {{ this.formataData(`${contato.ultima_interacao}`) }}
                            </p>
                        </div>
                        <div class="column is-narrow">
                            <nav class="level is-mobile">
                                <div class="level-left">

                                    <p class="pb-1 is-size-5 level-item"><strong>Tags: </strong></p>

                                    <template v-if=contato.viu_acomodacoes>


                                        <figure class="image is-24x24 level-item" data-tooltip="Viu acomodações">
                                            <img :src="getImgPath('find')" alt="Viu acomodações">
                                        </figure>

                                    </template>

                                    <template v-if=contato.criancas>


                                        <figure class="image is-24x24 level-item" data-tooltip="Família">
                                            <img :src="getImgPath('family')" alt="Família">
                                        </figure>


                                    </template>

                                    <template v-if="!contato.criancas && !contato.viu_acomodacoes">
                                        <div class="level-item pl-1 pr-1">

                                            <span class="is-size-5">Sem tags</span>
                                        </div>
                                    </template>

                                </div>
                            </nav>
                        </div>
                    </div>
                    <hr>
                    <div class="columns is-multiline">
                        <div class="column">
                            <p class="is-size-5"><strong>Alterar status:</strong></p>
                            <div class="field has-addons pt-1">

                                <div class="control">
                                    <div class="select is-normal">
                                        <select v-model="this.status"
                                            :disabled="!this.contato.membro_atribuido || parseInt(this.$store.state.user.id) !== contato.membro_atribuido?.id">
                                            <option value="Em Aberto">Em Aberto</option>
                                            <option value="Finalizado">Finalizado</option>
                                            <option value="Reservado">Reservado</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control">
                                    <button class="button is-dark is-light" @click="atualizaContato"
                                        :disabled="!this.contato.membro_atribuido || parseInt(this.$store.state.user.id) !== contato.membro_atribuido?.id">
                                        <strong>IR</strong>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <template
                                v-if="this.contato.membro_atribuido && (parseInt(this.$store.state.user.id) === contato.membro_atribuido?.id || parseInt(this.$store.state.user.id) === this.team.created_by?.id)">
                                <p class="is-size-5"><strong>Migrar contato para:</strong></p>
                                <div class="field has-addons pt-1">
                                    <div class="control">
                                        <div class="select">
                                            <select v-model="membro_selecionado">
                                                <option value="" selected>---</option>
                                                <option v-for="member in this.membros_time" v-bind:id="member.id"
                                                    v-bind:value="member.id">
                                                    {{ member.username }}</option>
                                                <option value="-1">Remover</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="control">
                                        <button class="button is-dark"
                                            @click="alterarMembroAtribuido"><strong>IR</strong></button>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <template v-if="!this.contato.membro_atribuido">
                                    <p class="is-size-5"><strong>Atribuir membro:</strong></p>
                                    <div class="field has-addons pt-1">
                                        <div class="control">
                                            <div class="select">
                                                <select v-model="membro_selecionado">
                                                    <option value="" selected>---</option>
                                                    <option v-for="member in this.team.members" v-bind:id="member.id"
                                                        v-bind:value="member.id">
                                                        {{ member.username }}</option>
                                                    <option value="-1">Remover</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="control">
                                            <button class="button is-dark"
                                                @click="alterarMembroAtribuido"><strong>IR</strong></button>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>

                </div>
            </div>

            <div class="column is-6 pb-0">
                <div class="box">
                    <h1 class="title has-text-centered"> Última Consulta ({{ consulta.data_consulta !== null ?
                        this.formataData(`${contato.data_consulta}`) : 'Sem dados' }})</h1>

                    <hr>
                    <div class="columns">
                        <div class="column pb-1">

                            <div class="field">
                                <label class="label">Data do Check-in:</label>
                                <input type="date" class="input is-normal" :readonly="!this.registrarConsulta"
                                    v-model="this.consulta.data_entrada">
                            </div>
                            <div class="field">
                                <label class="label">Data do Check-out:</label>
                                <input type="date" class="input is-normal" :readonly="!this.registrarConsulta"
                                    v-model="this.consulta.data_saida">
                            </div>

                        </div>
                        <div class="column pb-1">
                            <div class="field">
                                <label class="label">Adultos:</label>
                                <input type="number" class="input is-normal" :readonly="!this.registrarConsulta" min="1"
                                    max="5" v-model="this.consulta.quantidade_adultos">
                            </div>
                            <div class="field is-narrow">
                                <label class="label">Crianças:</label>
                                <input type="number" class="input is-normal" :readonly="!this.registrarConsulta" min="0"
                                    max="5" v-model="this.consulta.quantidade_criancas">
                            </div>

                        </div>
                    </div>

                    <hr>

                    <div class="columns is-multiline pt-2">
                        <div class="column is-12 p-0 pl-3">

                            <p class="is-size-5"><strong>Ações:</strong></p>
                        </div>
                        <div class="column is-narrow">

                            <div class="buttons is-center">
                                <a class="button is-info"
                                    v-if="this.consulta.data_consulta !== null && this.consulta.data_entrada !== null && this.data_saida !== null"
                                    target="_blank"
                                    :href="`${this.team.url_reserva ? this.formataURLReserva() : ''}`">Verificar
                                    Consulta</a>

                                <div :class="{ 'is-hidden': this.registrarConsulta }">
                                    <button class="button is-dark" @click="this.registrarConsulta = !this.registrarConsulta"
                                        :disabled="this.registrarConsulta">
                                        Nova Consulta</button>
                                </div>
                                <template v-if="this.registrarConsulta">

                                    <button class="button is-success" @click="salvarConsulta">Salvar</button>
                                    <button class="button is-danger" @click="redefinirConsulta">Cancelar</button>

                                </template>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div class="column is-12">

                <div class="box">
                    <div class="columns pb-0 is-vcentered is-centered">
                        <div class="column is-narrow">
                            <h1 class="title has-text-centered"> Históricos</h1>
                        </div>
                        <div class="column is-narrow">
                            <button class="button  is-success" @click="ativarModal"><strong>+ Novo</strong></button>
                        </div>
                    </div>

                    <hr>

                    <div :class="`modal ${this.add_historico ? 'is-active' : ''}`">
                        <div class="modal-background"></div>
                        <div class="modal-card">
                            <header class="modal-card-head">
                                <p class="modal-card-title">Adicionar Histórico</p>
                                <button class="delete" aria-label="close" @click="ativarModal"></button>
                            </header>
                        </div>
                        <div class="modal-content has-background-white py-5 px-5">
                            <form @submit.prevent="salvarHistorico">
                                <div class="field">

                                    <div class="control pb-5">
                                        <label class="label" for="">Categoria:</label>
                                        <div class="select is-normal">
                                            <select v-model="this.n_categoria" required>
                                                <option value="Atualização" selected>Atualização</option>
                                                <option value="Negociação">Negociação</option>
                                                <option value="Confirmação">Confirmação</option>
                                                <option value="Outros">Outros</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="control">
                                        <label class="label" for="">Descrição:</label>

                                        <textarea class="textarea has-fixed-size" v-model="this.descricao"
                                            placeholder="Máx. 255 caracteres" maxlength="255" required></textarea>

                                        <p class="has-text-right pt-1" style="opacity: 0.42;">{{ this.descricao.length
                                        }}/255
                                        </p>

                                    </div>

                                    <div class="control has-text-right pt-2">
                                        <button class="button  is-success"><strong>Salvar
                                            </strong></button>

                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>

                    <div :class="`modal ${this.ver_historico ? 'is-active' : ''}`">
                        <div class="modal-background"></div>
                        <div class="modal-card">
                            <header class="modal-card-head">
                                <p class="modal-card-title">Histórico de {{ this.historico.categoria }}</p>
                                <button class="delete" aria-label="close"
                                    @click="this.ver_historico = !this.ver_historico"></button>
                            </header>
                        </div>
                        <div class="modal-content has-background-white py-5 px-5">

                            <div>
                                <h2 class="subtitle"><strong>Dados:</strong></h2>
                                <h3 class="subtitle is-size-6">Histórico criado por <strong>{{
                                    this.historico.criado_por?.username }} </strong>,
                                    em {{ this.formataDataHistorico(this.historico.criado_em).split(' ')[0] }} às {{
                                        this.formataDataHistorico(this.historico.criado_em).split(' ')[1] }}h.</h3>
                            </div>
                            <hr>
                            <h2 class="subtitle"><strong>Descrição:</strong></h2>
                            <h3 class="subtitle is-size-6">{{ this.historico.descricao }}</h3>


                        </div>
                    </div>

                    <div class="table-container ">

                        <table class="table is-hoverable is-narrow is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Categoria</th>
                                    <th class="">Descrição</th>
                                    <th>Criado Por</th>
                                    <th>Data/Hora</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>

                                <tr v-for="  historico  in  historicos " v-bind:key="historico.id">
                                    <td>{{ historico.categoria }}</td>
                                    <td>{{ historico.descricao.slice(0, 70) }}</td>
                                    <td maxlength="50">{{ historico.criado_por.username }}</td>
                                    <td>{{ this.formataDataHistorico(historico.criado_em) }}</td>
                                    <td><a class="button" @click="getHistorico(historico.id)">Ver</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {

    name: "Contato",
    data() {
        return {
            contato: {},
            historicos: {},
            historico: {},
            add_historico: false,
            ver_historico: false,
            status: "",
            descricao: "",
            n_categoria: "Atualização",
            curva: 'Sem dados',
            team: {},
            membros_time: [],
            membro_selecionado: "",

            registrarConsulta: false,

            consulta: {}

        }
    },

    mounted() {
        this.getLead()
        this.getHistoricos()

        // this.contatos_selecionados.splice(this.team?.members.indexOf(this.$store.state.user.id), 1)
    },
    methods: {

        redefinirConsulta() {
            this.consulta = {
                data_consulta: this.contato.data_consulta,
                data_entrada: this.contato.data_entrada,
                data_saida: this.contato.data_saida,
                quantidade_adultos: this.contato.quantidade_adultos,
                quantidade_criancas: this.contato.quantidade_criancas,
            }
            this.registrarConsulta = false
        },


        async salvarConsulta() {

            const date = new Date()

            var dia = date.getDate();
            var mes = date.getMonth() + 1;
            var ano = date.getFullYear();

            dia = dia < 10 ? "0" + dia : dia;
            mes = mes < 10 ? "0" + mes : mes;

            console.log(this.consulta.data_saida)

            this.contato.data_consulta = `${ano}-${mes}-${dia}`;
            this.contato.data_entrada = this.consulta.data_entrada;
            this.contato.data_saida = this.consulta.data_saida;
            this.contato.quantidade_adultos = this.consulta.quantidade_adultos;
            this.contato.quantidade_criancas = this.consulta.quantidade_criancas;

            const contatoID = this.$route.params.id

            await axios
                .patch(`/api/v1/contatos/${contatoID}/`, this.contato)
                .then(response => {
                    toast({
                        message: 'Consulta cadastrada',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                    this.getLead()


                })
                .catch(error => {
                    console.log(error)
                })
            this.registrarConsulta = false
        },


        getFilteredMembers() {

            return this.team.members?.filter(member => member?.id !== this.contato.membro_atribuido.id)
        },

        async getTime() {

            await axios
                .get('/api/v1/teams/get_my_team')
                .then(response => {
                    this.team = response.data
                    this.membros_time = this.getFilteredMembers()
                    // console.log(this.$store.state.user.username)
                })
                .catch(error => {
                    console.log(error)

                })

        },
        async getHistoricos() {
            const leadID = this.$route.params.id

            const data = {
                contato: leadID
            }

            await axios
                .post('/api/v1/historicos/get_historicos/', data)
                .then(response => {
                    this.historicos = response.data.historicos
                    // console.log(response.data)

                })
                .catch(error => {
                    console.log(error)

                })

        },
        async getLead() {
            // this.$store.commit('setIsLoading',true)

            const leadID = this.$route.params.id

            await axios
                .get(`/api/v1/contatos/${leadID}`)
                .then(response => {
                    this.contato = response.data
                    this.status = this.contato.status
                    this.curva = this.calcularCurva(this.contato.data_entrada, this.contato.data_consulta)

                    this.consulta = {
                        data_consulta: this.contato.data_consulta,
                        data_entrada: this.contato.data_entrada,
                        data_saida: this.contato.data_saida,
                        quantidade_adultos: this.contato.quantidade_adultos,
                        quantidade_criancas: this.contato.quantidade_criancas,
                    }

                    this.getTime()

                })
                .catch(error => {
                    console.log(error)
                })

            // this.$store.commit('setIsLoading',false)
        },

        async getHistorico(id_historico) {

            const filtro = {
                id_contato: this.$route.params.id
            }

            await axios
                .get(`/api/v1/historicos/${id_historico}`, { params: filtro })
                .then(response => {

                    this.historico = response.data

                    this.ver_historico = !this.ver_historico

                })
                .catch(error => {
                    console.log(error)
                })
        },
        async alterarMembroAtribuido() {

            const dados = {
                id_membro: this.membro_selecionado,
                contatos: [this.contato?.id],
            }

            if (this.membro_selecionado === '') {
                toast({
                    message: 'Selecione um membro da equipe para migrar o contato \n',
                    type: 'is-danger',
                    dimissible: true,
                    pauseOnHover: true,
                    durations: 2000,
                    position: 'top-center',
                })
                return
            }

            await axios
                .post('/api/v1/contatos/atribuir_membro_contatos/', dados)
                .then(response => {

                    const msg = this.membro_selecionado === '-1' ? 'Atribuição removida' : `O contato foi atribuído ao membro ${response.data}`

                    toast({
                        message: msg,
                        type: 'is-info',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'top-center',
                    })
                    this.getLead()
                    this.membro_selecionado = ''
                })
                .catch(error => {

                })


        },
        async salvarHistorico() {
            const id_contato = this.$route.params.id

            const historico_novo = {

                categoria: this.n_categoria,
                descricao: this.descricao,
                contato: id_contato
            }

            await axios
                .post("/api/v1/historicos/salvar_historico/", historico_novo)
                .then(request => {

                    this.ativarModal()

                    toast({
                        message: 'Histórico Salvo!',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'top-center',
                    })

                    this.getHistoricos()

                })
                .catch(error => {

                    console.log(error)

                })
        },
        formataData(data) {

            // console.log(data)

            if (data === null) {
                return '-'
            }

            data = data.split('-')


            return `${data[2]}/${data[1]}/${data[0]}`
        },

        formataDataHistorico(data) {

            let dataObj = new Date(data);

            let dia = dataObj.getDate();
            let mes = dataObj.getMonth() + 1; // Os meses em JavaScript são indexados a partir de 0, então adicionamos 1 para obter o mês correto
            let ano = dataObj.getFullYear();
            let hora = dataObj.getHours();
            let minutos = dataObj.getMinutes();

            dia = dia < 10 ? "0" + dia : dia;
            mes = mes < 10 ? "0" + mes : mes;
            minutos = minutos < 10 ? "0" + minutos : minutos;

            return dia + "/" + mes + "/" + ano + " " + hora + ":" + minutos;

        },

        getImgPath(nome) {

            return require(`../../assets/${nome}.png`)
        },

        getStatusColor(status) {

            if (status === "Novo") {
                // return "hsl(207, 61%, 53%) !important"
                return "info"
            } else if (status === "Em Aberto") {
                // return "hsl(44, 100%, 77%) !important"
                return "warning"
            } else if (status === "Finalizado") {
                // return "hsl(348, 86%, 61%) !important"
                return "danger"
            }
            // return "hsl(153, 53%, 53%) !important"
            return "success"
        },

        calcularCurva(data1, data2) {

            const data_consulta = new Date(data1)
            const data_interesse = new Date(data2)

            if (!data1) {
                return "Sem dados"
            }
            const curva = (data_interesse.getMonth() - data_consulta.getMonth())
            if (curva < 0) {
                return curva + 12
            }
            if (curva === 1) {
                return curva
            }
            return curva
        },

        atualizaContato(status) {
            this.contato.status = this.status

            const contatoID = this.$route.params.id

            axios
                .patch(`/api/v1/contatos/${contatoID}/`, this.contato)
                .then(response => {
                    // toast({
                    //     message: 'Status atualizado!',
                    //     type: 'is-success',
                    //     dismissible: true,
                    //     pauseOnHover: true,
                    //     duration: 2000,
                    //     position: 'bottom-right',
                    // })
                    this.getLead()
                    // console.log(this.contato)
                    // console.log(this.contato.canal)

                })
                .catch(error => {
                    console.log(error)
                })
        },
        ativarModal() {
            this.add_historico = !this.add_historico
            this.descricao = ''
            this.n_categoria = 'Atualização'
        },
        formataURLReserva() {

            var url_formatada = this.team?.url_reserva.replace('quantidade_adultos', this.consulta.quantidade_adultos)
            url_formatada = url_formatada.replace('quantidade_criancas', this.consulta.quantidade_criancas)

            if (url_formatada.search("omnibees") !== -1) {
                const d1 = this.consulta.data_entrada.split('-')
                const d2 = this.consulta.data_saida.split('-')

                url_formatada = url_formatada.replace('data_entrada', d1[2] + d1[1] + d1[0])
                url_formatada = url_formatada.replace('data_saida', d2[2] + d2[1] + d2[0])

                var input = '5'
                for (let i = 1; i < this.consulta.quantidade_criancas; i++) {
                    input += '%3B5'
                }
                url_formatada = url_formatada.replace('ch_input', input)
                url_formatada = url_formatada.replace('ag_input', input)

            } else {
                url_formatada = url_formatada.replace('data_entrada', this.consulta.data_entrada)
                url_formatada = url_formatada.replace('data_saida', this.consulta.data_saida)
            }

            return url_formatada

        },

    }

}

</script>

<style>
.dot {

    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
}

.canal {

    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 99;
    border-radius: 50%;
    border: 1px solid #ffffff;
}
</style>