<template>
    <div class="flexbox">
        <canvas height="300" width="250" ref="mychart" id="myChart"></canvas>
    </div>
</template>


<script>
import { Chart } from 'chart.js/auto'

export default {
    data() {
        return {
            dados: [],
            colunas: [],
            chart: {},

        }
    },

    name: 'ChartComponent',
    mounted() {
        const ctx = this.$refs.mychart


        var sum = 0
        var cont = 0
        for (var i = 0; i < this.dados.length; i++) {
            sum += this.dados[i]
            cont++
        }
        const backgroundColors = []
        const borderColors = []
        const lineData = []
        const lineData2 = []
        const media = sum / cont

        for (i = 0; i < this.dados.length; i++) {
            if (this.dados[i] >= media) {
                backgroundColors.push('rgba(75, 192, 192, 0.5)')
                borderColors.push('rgba(75, 192, 192)')
                // } else if (this.dados[i] < media && this.dados[i] > 10) {
                //     backgroundColors.push("rgba(255, 159, 64, 0.5)")
                //     borderColors.push("rgba(255, 159, 64)")
            } else {
                backgroundColors.push('rgba(255, 99, 132, 0.5)')
                borderColors.push('rgba(255, 99, 132)')
            }
            // lineData.push(parseInt(media.toFixed(0)))
            lineData.push(media)
            lineData2.push(10)
        }

        // console.log(lineData)

        this.mychart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: this.colunas,
                datasets: [{
                    type: 'bar',
                    label: 'Consultas',
                    stack: 'Num.',
                    data: this.dados,
                    borderWidth: 2,
                    backgroundColor: backgroundColors,
                    borderColor: borderColors,

                    order: 2

                }, {
                    type: 'line',
                    label: 'Média',
                    borderWidth: 2,
                    lineTension: 0,
                    borderColor: "rgba(255, 159, 64)",
                    backgroundColor: 'rgba(255, 159, 64)',
                    data: lineData,
                    order: 1,
                },]
            },

            options: {
                maintainAspectRatio: false,
                scales: {
                    x: {
                        beginAtZero: true
                    }
                },
                elements: {
                    point: {
                        radius: 0
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                        align: 'start',
                        position: 'bottom'
                    },

                    title: {
                        display: false,
                        text: 'Mês de interesse'
                    }
                }
            },

        });

    },
    methods: {
        atualizarLabels(labels) {

            this.mychart.data.labels = labels

            this.mychart.update();
        },
        atualizarData(data) {
            var sum = 0
            var cont = 0
            for (var i = 0; i < data.length; i++) {
                sum += data[i]
                cont++
            }
            const backgroundColors = []
            const borderColors = []
            const lineData = []

            const media = sum / cont

            for (i = 0; i < data.length; i++) {
                if (data[i] >= media) {
                    backgroundColors.push('rgba(75, 192, 192, 0.5)')
                    borderColors.push('rgba(75, 192, 192)')
                    // } else if (this.dados[i] < media && this.dados[i] > 10) {
                    //     backgroundColors.push("rgba(255, 159, 64, 0.5)")
                    //     borderColors.push("rgba(255, 159, 64)")
                } else {
                    backgroundColors.push('rgba(255, 99, 132, 0.5)')
                    borderColors.push('rgba(255, 99, 132)')
                }
                // lineData.push(parseInt(media.toFixed(0)))
                lineData.push(media)
            }


            this.mychart.data.datasets[0].data = data
            this.mychart.data.datasets[0].backgroundColor = backgroundColors
            this.mychart.data.datasets[0].borderColor = borderColors

            this.mychart.data.datasets[1].data = lineData

            this.mychart.update();
        }
    }
}


</script>

<style>
.flexbox {
    display: flex;

}
</style>