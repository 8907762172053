<script>
// @ is an alias to /src
export default {
  name: 'HomeView',
  components: {
  },
  beforeCreate() {
    if (!this.$store.state.isAuthenticated) {
      this.$router.push('/log-in')
    }
    else {
      this.$router.push('/dashboard')
    }
  }
}
</script>
