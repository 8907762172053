<template>
    <div class="container">
        <div class="columns is-multiline">


            <div class="column is-12 is-narrow pb-1 pt-1">
                <div class="level-item box">
                    <h1 class="title is-size-1">Visão Geral</h1>
                </div>
            </div>

            <div class="column is-12 pb-1 ">
                <div class="box is-narrow">
                    <div class="level">
                        <div class="level-item">
                            <div class="control">
                                <p class="pb-1 is-size-5"><strong>Período: </strong></p>
                                <div class="select">
                                    <select v-model="query_range" @change="ativarPersonalizado">

                                        <option value="week" selected>Última semana</option>
                                        <option value="year">Este ano</option>
                                        <option value="month">Este mês</option>
                                        <option value="">Sempre</option>
                                        <option value="-1">Personalizado</option>

                                    </select>
                                </div>
                            </div>

                        </div>
                        <div class="level-item">
                            <div>
                                <!-- <label class="checkbox pb-2 is-size-5">
                                    <input type="checkbox" v-model="this.ehPersonalizado" @change="ativarPersonalizado">

                                </label> -->
                                <p class="is-size-5 pb-1 has-text-centered"><strong>Intervalo Personalizado:</strong></p>

                                <div class="field" :class="{ 'has-addons': isDesktop }">

                                    <div class="control">
                                        <!-- <label class="label has-text-centered">Início:</label> -->
                                        <input :disabled="!this.ehPersonalizado" type="date" placeholder="Início"
                                            class="input" v-model="query_periodo_inicio">
                                    </div>

                                    <div class="control" :class="{ 'pt-1': !this.isDesktop }">
                                        <!-- <label class="label has-text-centered">Final:</label> -->
                                        <input :disabled="!this.ehPersonalizado" type="date" class="input"
                                            v-model="query_periodo_fim">

                                    </div>
                                    <div class="control" :class="{ 'pt-2': !this.isDesktop }">

                                        <button @click="getContatos" :disabled="!this.ehPersonalizado" type="date"
                                            class="button is-dark"
                                            :class="{ 'is-fullwidth': !this.isDesktop }"><strong>Aplicar</strong></button>

                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="level-item pt-5">
                            <div class="control">
                                <button class="button is-danger is-narrow" @click="limparFiltros">
                                    <span class="icon">
                                        <font-awesome-icon icon="trash" />
                                    </span>
                                    <span><strong>Limpar Filtros</strong></span>
                                </button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-3 pb-1">
                <div class="box">
                    <span class="icon-text level-item pb-2">
                        <span class="icon pt-1"><img src="../../assets/reservai_logo.png" alt="ícone do ReservAI"></span>
                        <span class="title is-4" :class="{ 'is-5': !this.isDesktop }">{{ this.isDesktop ? "Total ReservAI" :
                            "ReservAI" }}</span>
                    </span>

                    <template v-if="this.isDesktop">
                        <h4 class="subtitle is-3 has-text-centered">{{ this.total_contatos }} Contatos</h4>
                    </template>
                    <template v-else>
                        <h4 class="title is-2 has-text-centered" :class="{ 'is-3': !this.isDesktop }">{{
                            this.total_contatos }}</h4>

                        <h4 class="subtitle is-5 has-text-centered">Contatos</h4>
                    </template>

                </div>
            </div>


            <div class="column is-3 pb-1 " :class="{ 'pr-1': this.isDesktop }">
                <div class="box">
                    <span class="icon-text level-item pb-2">
                        <span class="icon pt-1"><img src="../../assets/Instagram.png" alt="ícone do instagram"></span>
                        <span class="title is-4" :class="{ 'is-5': !this.isDesktop }">Instagram</span>
                    </span>
                    <template v-if="this.isDesktop">
                        <h4 class="subtitle is-3 has-text-centered">{{ qtd_canais.Instagram }} Contatos</h4>
                    </template>
                    <template v-else>
                        <h4 class="title is-2 has-text-centered" :class="{ 'is-3': !this.isDesktop }">{{
                            qtd_canais.Instagram }}</h4>

                        <h4 class="subtitle is-5 has-text-centered">Contatos</h4>
                    </template>
                </div>
            </div>

            <div class="column is-3 pb-1" :class="{ 'pl-1': this.isDesktop }">
                <div class="box">
                    <span class="icon-text level-item pb-2">
                        <span class="icon pt-1"><img src="../../assets/Whatsapp.png" alt="ícone do whatsapp"></span>
                        <span class="title is-4" :class="{ 'is-5': !this.isDesktop }">WhatsApp
                        </span>
                    </span>
                    <template v-if="this.isDesktop">
                        <h4 class="subtitle is-3 has-text-centered">{{ qtd_canais.Whatsapp }} Contatos</h4>
                    </template>
                    <template v-else>
                        <h4 class="title is-2 has-text-centered" :class="{ 'is-3': !this.isDesktop }">{{
                            qtd_canais.Whatsapp }}</h4>

                        <h4 class="subtitle is-5 has-text-centered">Contatos</h4>
                    </template>

                </div>
            </div>

            <div class="column is-3 pb-1 ">
                <div class="box">
                    <span class="icon-text level-item pb-2">
                        <span class="icon"><img src="../../assets/Facebook.png" alt="ícone do facebook"></span>
                        <span class="title is-4" :class="{ 'is-5': !this.isDesktop }">Facebook</span>
                    </span>

                    <template v-if="this.isDesktop">
                        <h4 class="subtitle is-3 has-text-centered">{{ qtd_canais.Facebook }} Contatos</h4>
                    </template>
                    <template v-else>
                        <h4 class="title is-2 has-text-centered" :class="{ 'is-3': !this.isDesktop }">{{
                            qtd_canais.Facebook }}</h4>

                        <h4 class="subtitle is-5 has-text-centered">Contatos</h4>
                    </template>


                </div>
            </div>

            <div class="column is-6 pb-0 pr-1 pt-1" :class="{ 'is-12 pl-1 pt-3': !this.isDesktop }">

                <div class="box" :class="{ 'chart-box': this.isDesktop }">
                    <h2 class="title is-3 has-text-centered">MÊS DE INTERESSE</h2>

                    <div class="columns is-multiline">
                        <div class="column is-12 pb-5 pt-0">
                            <div :class="{ 'chart-view': this.isDesktop }">
                                <ChartComponent ref="chartMesInteresse" />
                            </div>
                        </div>

                        <div class="column">
                            <div class="columns  is-centered">
                                <div class="column is-narrow pb-2">
                                    <h4 class="title is-5 has-text-centered"><strong>MAIOR INTERESSE</strong></h4>
                                    <h4 class="subtitle is-5 has-text-centered">{{ this.maior_interesse }}</h4>
                                </div>
                                <div class="column  is-narrow pb-2">
                                    <h4 class="title is-5 has-text-centered"><strong>MENOR INTERESSE</strong></h4>
                                    <h4 class="subtitle is-5 has-text-centered">{{ this.menor_interesse }}</h4>
                                </div>
                                <div class="column is-narrow pb-2">
                                    <h4 class="title is-5 has-text-centered"><strong>CONSULTAS</strong></h4>
                                    <h4 class="subtitle is-5subtitle is-5subtitle is-5 has-text-centered">{{
                                        this.consultas_realizadas > 0 ?
                                        this.consultas_realizadas : 'Sem dados' }}</h4>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="column is-6 pb-0 pl-1 pt-1" :class="{ 'is-12 pr-1 pt-3': !this.isDesktop }">
                <div class="box" :class="{ 'chart-box': this.isDesktop }">
                    <h2 class="title is-3 has-text-centered">CURVA DE RESERVA</h2>

                    <div class="columns is-multiline">
                        <div class="column is-12 pb-5 pt-0">
                            <div :class="{ 'chart-view': this.isDesktop }">
                                <LineChartComponent ref="chartCurva" />
                            </div>
                        </div>

                        <div class="column is-12">
                            <div class="columns is-centered pb-1">
                                <div class="column is-narrow">
                                    <h4 class="title is-5 has-text-centered"><strong>CURVA MÉDIA</strong></h4>
                                    <h4 class="subtitle is-5subtitle is-5subtitle is-5 has-text-centered">{{
                                        this.curva_media === "NaN" ? 'Sem dados' :
                                        this.curva_media
                                    }}
                                        {{
                                            this.curva_media == 1 ? "Mês" :
                                            this.curva_media === "NaN" ? '' : 'Meses' }}</h4>
                                </div>
                                <div class="column is-narrow pb-2">
                                    <h4 class="title is-5 has-text-centered"><strong>CURVA MÍNIMA</strong></h4>
                                    <h4 class="subtitle is-5subtitle is-5 has-text-centered">{{ this.curva_minima == 999 ?
                                        'Sem dados' :
                                        this.curva_minima
                                    }}
                                        {{
                                            this.curva_minima == 1 ? "Mês" :
                                            this.curva_minima == 999 ? '' : 'Meses' }}
                                    </h4>
                                </div>
                                <div class="column is-narrow pb-2">
                                    <h4 class="title is-5 has-text-centered"><strong>CURVA MÁXIMA</strong></h4>
                                    <h4 class="subtitle is-5 has-text-centered">{{ this.curva_maxima == 0 ? 'Sem dados' :
                                        this.curva_maxima }}
                                        {{
                                            this.curva_maxima == 1 ? "Mês" :
                                            this.curva_maxima == 0 ? '' : 'Meses' }}
                                    </h4>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="column is-6 m-0 pr-1" :class="{ 'is-12 pl-1': !this.isDesktop }">
                <div class="box" :class="{ 'chart-box': this.isDesktop }">
                    <h2 class="title is-3 has-text-centered">CONTATOS POR ESTADO</h2>
                    <div>

                        <Mapa ref="mapa" />

                    </div>
                </div>
            </div>
            <div class="column is-6 m-0 pl-1" :class="{ 'is-12 pr-1': !this.isDesktop }">
                <div class="box" :class="{ 'chart-box': this.isDesktop }">
                    <h2 class="title is-3 has-text-centered">CONTATOS POR STATUS</h2>
                    <br>
                    <br>

                    <div class="columns is-vcentered is-centered">
                        <div class="column is-narrow">
                            <div :class="{ 'chart-view': this.isDesktop }">
                                <PieChartMembrosAtribuidos ref="pieChart" />
                            </div>
                        </div>
                        <div class="column is-narrow">
                            <p class="is-size-4 pl-6 pb-5" v-for="quantidade, status in this.info_status"><span class="dot"
                                    :style="`background-color:${this.getStatusColor(status)};`"></span> {{
                                        status }}:
                                {{ quantidade }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChartComponent from '@/components/ChartComponent.vue';
import LineChartComponent from '@/components/LineChartComponent.vue';
import PieChartMembrosAtribuidos from '@/components/PieChartMembrosAtribuidos.vue';
import Mapa from '@/components/Mapa.vue';
import axios from 'axios';
import { toast } from 'bulma-toast'

import json from '@/assets/estados.json'
import dddjson from '@/assets/estadosPorDDD.json'
export default {
    name: "Dashboard",
    data() {
        return {


            isDesktop: '',
            dashboard: "",
            query_range: 'week',
            query_periodo_fim: '',
            query_periodo_inicio: '',
            contatos: [],
            total_contatos: 0,
            ehPersonalizado: false,
            qtd_canais: {
                Instagram: 0,
                Whatsapp: 0,
                Facebook: 0,
            },
            labels_mes_interesse: [],
            dados_mes_interesse: [],
            qtd_viu_acomodacoes: 0,
            qtd_casais: 0,
            estaCarregando: true,
            maior_interesse: '',
            menor_interesse: '',
            curva_media: 0,
            curva_minima: 999,
            curva_maxima: 0,
            consultas_realizadas: 0,

            dados_status: [],
            colunas_status: [],
            info_status: {},

            estados_Json: Object.assign({}, json),
            ddd_Json: Object.assign({}, dddjson),
            estados: []

        };
    },
    mounted() {
        window.addEventListener('resize', this.updateIsDesktop)
        this.updateIsDesktop();
        this.getContatos();
        // this.getContatosPorEstado();
        // this.getStatusEquipe();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateIsDesktop);
    },
    methods: {
        updateIsDesktop() {
            this.isDesktop = window.innerWidth >= 1024;

        },
        async getContatos() {
            // this.$store.commit('setIsLoading', true)
            this.estaCarregando = true
            this.dados_mes_interesse = []
            this.labels_mes_interesse = []
            var filtro = {}

            if (this.ehPersonalizado) {
                var filtro = {

                    ultima_interacao_after: this.query_periodo_inicio,
                    ultima_interacao_before: this.query_periodo_fim,


                }
            } else {
                var filtro = {
                    ultima_interacao_range: this.query_range
                }
            }

            await axios
                .get(`/api/v1/contatos/`, { params: filtro })
                .then(response => {

                    this.contatos = response.data
                    this.total_contatos = this.contatos.length
                    // console.log(this.contatos)
                    this.getDataSets()
                    this.getCanais()
                    this.getContatosPorEstado();
                    this.getStatusEquipe();

                })
                .catch(error => {

                    console.log(error)
                    if (error.response?.status === 401) {

                        axios.defaults.headers.common['Authorization'] = ""
                        this.$store.commit('logoutUser')
                        this.$store.commit('removeToken')
                        this.$router.push('/log-in')

                        toast({
                            message: 'Sessão do usuário expirada.\n Por favor, faça o login novamente.',
                            type: 'is-danger is-light',
                            dimissible: true,
                            pauseOnHover: true,
                            durations: 2000,
                            position: 'top-center',
                        })


                    }
                })



        },

        async getStatusEquipe() {
            this.esta_carregando_pie = true
            // console.log(this.query_range)
            var request = {}
            if (this.query_range === "-1") {
                request = {
                    intervalo: {
                        data_inicio: this.query_periodo_inicio,
                        data_final: this.query_periodo_fim
                    },
                    membro: ''
                };
            }
            else {
                request = {
                    intervalo: this.query_range,
                    membro: ''
                };
            }

            // console.log(request.intervalo)

            await axios
                .post("/api/v1/teams/get_status_equipe/", request)
                .then(response => {
                    // console.log(response.data)
                    this.dados_status = response.data.status_contatos.status;
                    this.colunas_status = response.data.status_contatos.quantidade;
                    this.$refs.pieChart.atualizarDados(this.dados_status, this.colunas_status);

                })
                .catch(error => {
                    console.log(error);
                });


            for (let i = 0; i < this.dados_status.length; i++) {

                this.info_status[this.dados_status[i]] = this.colunas_status[i]

            }

            // console.log(this.info_status)
            this.esta_carregando_pie = false
        },
        async getCanais() {

            this.qtd_canais.Instagram = 0
            this.qtd_canais.Whatsapp = 0
            this.qtd_canais.Facebook = 0

            const arrayContatos = Array.from(this.contatos)
            arrayContatos.forEach(obj => {
                const canal = obj.canal
                this.qtd_canais[canal] += 1

            })
        },
        extrairMes(data) {

            const data_completa = new Date(data)
            const mes = data_completa.toLocaleString("pt-BR", { month: "numeric" })

            return mes

        },
        calcularCurva(data1, data2) {

            const data_consulta = new Date(data1)
            const data_interesse = new Date(data2)

            if (!data1) {
                return "-"
            }

            const curva = (data_interesse.getMonth() - data_consulta.getMonth())
            if (curva < 0) {
                return curva + 12
            }

            return curva
        },

        getDataSets() {

            this.qtd_viu_acomodacoes = 0
            this.qtd_casais = 0
            this.menor_interesse = 'Sem dados'
            this.maior_interesse = 'Sem dados'
            this.curva_maxima = 0
            this.curva_minima = 999
            this.consultas_realizadas = 0



            const meses = [
                {
                    abrev: 'Jan',
                    nome: 'Janeiro',
                    quantidade: 0
                },
                {
                    abrev: 'Fev',
                    nome: 'Fevereiro',
                    quantidade: 0
                },
                {
                    abrev: 'Mar',
                    nome: 'Março',
                    quantidade: 0
                },
                {
                    abrev: 'Abr',
                    nome: 'Abril',
                    quantidade: 0
                },
                {
                    abrev: 'Maio',
                    nome: 'Maio',
                    quantidade: 0
                },
                {
                    abrev: 'Jun',
                    nome: 'Junho',
                    quantidade: 0
                },
                {
                    abrev: 'Jul',
                    nome: 'Julho',
                    quantidade: 0
                },
                {
                    abrev: 'Ago',
                    nome: 'Agosto',
                    quantidade: 0
                },
                {
                    abrev: 'Set',
                    nome: 'Setembro',
                    quantidade: 0
                },
                {
                    abrev: 'Out',
                    nome: 'Outubro',
                    quantidade: 0
                },
                {
                    abrev: 'Nov',
                    nome: 'Novembro',
                    quantidade: 0
                },
                {
                    abrev: 'Dez',
                    nome: 'Dezembro',
                    quantidade: 0
                },
            ]

            var cont = 0
            var soma = 0
            const curvas = []

            // console.log(this.contatos)

            const arrayContatos = Array.from(this.contatos)
            arrayContatos.forEach(obj => {
                if (obj.viu_acomodacoes) {
                    this.qtd_viu_acomodacoes += 1
                }

                if (obj.criancas) {
                    this.qtd_casais += 1
                }
                if (obj.data_consulta) {

                    this.consultas_realizadas++
                    const mes = this.extrairMes(obj.data_entrada)
                    meses[mes - 1]['quantidade'] += 1
                    const curva = this.calcularCurva(obj.data_consulta, obj.data_entrada)
                    cont++

                    soma += curva

                    if (curva < this.curva_minima && curva > 0) {
                        this.curva_minima = curva
                    }
                    if (curva > this.curva_maxima) {
                        this.curva_maxima = curva
                    }
                    curvas.push(curva)
                }
            })
            // console.log(curvas)

            this.curva_media = (soma / cont).toFixed(0)

            this.labels_mes_interesse = []
            this.dados_mes_interesse = []

            var maior_qtd = 0
            var menor_qtd = 999

            meses.forEach(obj => {
                if (obj.quantidade > 0) {
                    this.labels_mes_interesse.push(obj.abrev)
                    this.dados_mes_interesse.push(obj.quantidade)
                    if (obj.quantidade > maior_qtd) {
                        maior_qtd = obj.quantidade
                        this.maior_interesse = obj.nome
                    }
                    if (obj.quantidade < menor_qtd) {
                        menor_qtd = obj.quantidade
                        this.menor_interesse = obj.nome
                    }


                }
            })

            this.$refs.chartMesInteresse.atualizarLabels(this.labels_mes_interesse)
            this.$refs.chartMesInteresse.atualizarData(this.dados_mes_interesse)

            this.$refs.chartCurva.atualizarData([this.curva_minima, this.curva_media, this.curva_maxima])

            this.estaCarregando = false

        },

        ativarPersonalizado() {
            if (this.query_range == '-1') {
                this.ehPersonalizado = true
            } else {
                this.query_periodo_inicio = ''
                this.query_periodo_fim = ''
                this.ehPersonalizado = false
                this.getContatos()

            }

        },

        getContatosPorEstado() {

            this.estados_Json = Object.assign({}, json)

            // console.log(this.estados_Json)

            this.estadosArray = Object.entries(this.estados_Json).map(([sigla, valor]) => ({
                sigla,
                valor
            }));
            var total = 0;
            const arrayContatos = Array.from(this.contatos)
            arrayContatos.forEach(obj => {

                if (obj.whatsapp) {
                    const ddd = this.extrairDDD(obj.whatsapp)

                    const sigla_estado = this.ddd_Json.estadoPorDdd[ddd]

                    total++

                    if (sigla_estado != undefined) {
                        this.estados_Json[sigla_estado] += 1
                    }

                }
            })

            // console.log(this.estados_Json)
            this.$refs.mapa.renderizarMapa(this.estados_Json, total)

        },

        extrairDDD(telefone) {
            const regex = /(?:\+55|\+0|\+|0{0,2}55)?(\d{2})/; // Regex para encontrar o DDD em diferentes formatos
            const match = telefone.match(regex);

            // console.log(match);

            if (match && match[1]) {
                return match[1];
            }


        },
        limparFiltros() {
            this.query_range = 'week'
            this.query_periodo_fim = ''
            this.query_periodo_inicio = ''
            this.ehPersonalizado = false
            this.getContatos()

        },
        getStatusColor(status) {

            if (status === "Novo") {
                return "hsl(207, 61%, 53%) !important"
                // return "info"
            } else if (status === "Em Aberto") {
                return "hsl(44, 100%, 77%) !important"
                // return "warning"
            } else if (status === "Finalizado") {
                return "hsl(348, 86%, 61%) !important"
                // return "danger"
            }
            return "hsl(153, 53%, 53%) !important"
            // return "success"
        },


    },


    components: { ChartComponent, LineChartComponent, Mapa, PieChartMembrosAtribuidos },

}


</script>



<style>
.vl {
    border-left: 3px solid rgb(170, 170, 170);
    height: 350px;
}

.chart-view {
    max-height: 275px !important;

}

.chart-box {
    height: 460px !important;

}

.dot {

    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
}
</style>

<style>
@import '../../assets/styles/mapa.scss'
</style>