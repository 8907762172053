<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12 pb-0 pt-1">
                <div class="box">
                    <div class="level">
                        <div class="level-item pb-0">
                            <h1 class="title is-size-1"> Equipe {{ team.name }} ({{ team.members.length }}/5)</h1>
                        </div>

                    </div>
                </div>


            </div>

            <div class="column is-12">
                <!-- <h2 class="subtitle">Membros</h2> -->
                <div class="box">
                    <div class="table-container">
                        <table class="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Usuário</th>
                                    <th>Email</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="member in team.members" v-bind:key="member.id">
                                    <template v-if="this.$store.state.user.username === member.username">
                                        <td>{{ member.username }} (Você)</td>
                                    </template>
                                    <template v-else>
                                        <td>{{ member.username }}</td>
                                    </template>
                                    <td>{{ member.email }}</td>
                                    <template v-if="parseInt(this.$store.state.user.id) === this.team.created_by.id">
                                        <template v-if="parseInt(this.$store.state.user.id) !== member.id">
                                            <td><button class="button is-danger is-outlined is-small"
                                                    @click="confirmarRemocao(member.id, member.username)"><font-awesome-icon
                                                        icon="trash" /></button>
                                            </td>
                                        </template>
                                        <template v-else>

                                            <td>
                                                Moderador
                                            </td>

                                        </template>
                                    </template>
                                    <template v-else>
                                        <template v-if="member.id === this.team.created_by.id">
                                            <td>
                                                Moderador
                                            </td>
                                        </template>
                                        <template v-else>
                                            <td></td>
                                        </template>

                                    </template>

                                </tr>

                            </tbody>
                        </table>
                        <template v-if="parseInt(this.$store.state.user.id) === this.team.created_by.id">
                            <!-- Usar essa marcação, :to="", deixa mais fácil a manutenção do código caso haja alguma alteração na url do router/index.js -->
                            <!-- <router-link :to="{ 'name': 'AddMember' }" class="button is-success"><strong> +
                                Novo Membro </strong></router-link> -->
                            <div class="level-left">
                                <button class="button  is-success" @click="ativarModal"><strong> + Novo
                                        Membro</strong></button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <template v-if="this.remocao_confirmada">
                <div class="column">
                    <div class="notification is-light">
                        <div class="level">
                            <div class="level-left">
                                <span class="is-size-5">Você deseja remover o membro: <strong>{{
                                    this.username_membro_deletar }}</strong>
                                    ?</span>
                            </div>

                            <div class="level-right">
                                <div class="level-item">
                                    <button class="button is-success" @click="apagarMembro">Sim</button>
                                </div>

                                <div class="level-item">
                                    <button @click="cancelarRemocao" class="button is-danger">Não</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div class="column is-12 pb-0 pt-0">
                <div class="box">
                    <div class="columns is-multiline is-centered">

                        <div class="column is-12">
                            <h2 class="title is-size-3 has-text-centered" :class="{ 'is-size-4': !this.isDesktop }">
                                Rendimento
                                da
                                Equipe
                            </h2>
                        </div>

                    </div>
                </div>
            </div>

            <div class="column is-6" :class="{ 'is-12': !this.isDesktop }">
                <div class="box " :class="{ 'chart-box': this.isDesktop }">

                    <div class="control">

                        <div class="select" :class="{ 'is-fullwidth': !this.isDesktop }">
                            <select v-model="query_range_2" @change="getRendimento">

                                <option value="month">Este mês</option>
                                <option value="year">Este ano</option>
                                <option value="week">Últimos 7 dias</option>


                            </select>
                        </div>
                    </div>
                    <br>
                    <h2 class="is-size-3 has-text-centered" :class="{ 'is-size-4': !this.isDesktop }">Atendimento da Equipe
                    </h2>
                    <div :class="{ 'chart-view': this.isDesktop }">
                        <ChartMembrosAtribuidos ref="barChart" />
                    </div>
                </div>
            </div>
            <div class="column is-6 is-narrow" :class="{ 'is-12': !this.isDesktop }">
                <div class="box" :class="{ 'chart-box': this.isDesktop }">


                    <div class="control" :class="{ 'pt-2': !this.isDesktop }">
                        <!-- <p class="pb-1 is-size-5"><strong>Período: </strong></p> -->
                        <div class="select" :class="{ 'is-fullwidth': !this.isDesktop }">
                            <select v-model="membro_selecionado" @change="getStatusEquipe">
                                <option value="" selected> Todos </option>
                                <option v-for="member in this.team.members" v-bind:id="member.id"
                                    v-bind:value="member.username">
                                    {{ member.username }}</option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <h2 class="is-size-3 has-text-centered" :class="{ 'is-size-4': !this.isDesktop }">Status de
                        Atendimentos

                    </h2>

                    <br>
                    <div class="columns is-vcentered is-centered">

                        <div class="column is-narrow">
                            <div :class="{ 'chart-view': this.isDesktop }">
                                <PieChartMembrosAtribuidos ref="pieChart" />
                            </div>
                        </div>
                        <div class="column is-narrow">

                            <p class="is-size-4 pl-6 pb-5" v-for="quantidade, status in this.info_status"><span class="dot"
                                    :style="`background-color:${this.getStatusColor(status)};`"></span> {{
                                        status }}:
                                {{ quantidade }}</p>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div :class="`modal ${this.add_membro ? 'is-active' : ''}`">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Adicionar Membro</p>
                <button class="delete" aria-label="close" @click="ativarModal"></button>
            </header>
        </div>

        <div class="modal-content has-background-white py-5 px-5">
            <form @submit.prevent="submitForm">

                <div class="field">
                    <label class="label">Usuário:</label>
                    <div class="control">
                        <input type="text" name="username" class="input" v-model="username">
                    </div>
                    <label class="label">Email:</label>
                    <div class="control">
                        <input type="email" name="email" autocomplete="on" class="input" v-model="email">
                    </div>
                </div>

                <div class="field">
                    <label class="label">Senha:</label>
                    <div class="control">
                        <input type="password" name="password1" autocomplete="on" class="input" v-model="password1">
                    </div>
                </div>

                <div class="field">
                    <label class="label">Repita a senha:</label>
                    <div class="control">
                        <input type="password" name="new-password" autocomplete="on" class="input" v-model="password2">
                    </div>
                </div>

                <div class="notification is-danger is-light" v-if="errors.length">
                    <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                </div>

                <div class="field">
                    <div class="control">
                        <button class="button is-success">Adicionar membro</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import ChartComponent from '@/components/ChartComponent.vue';
import ChartMembrosAtribuidos from '@/components/ChartMembrosAtribuidos.vue';
import LineChartComponent from '@/components/LineChartComponent.vue'
import PieChartMembrosAtribuidos from '@/components/PieChartMembrosAtribuidos.vue';
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: "Team",
    // components: { ChartComponent, LineChartComponent },
    data() {
        return {
            team: {
                members: [],
                created_by: {}
            },
            id_membro_deletar: 0,
            username_membro_deletar: "",
            remocao_confirmada: false,
            add_membro: false,
            username: "",
            email: "",
            password1: "",
            password2: "",
            errors: [],

            esta_carregando: true,
            esta_carregando_pie: true,
            dados_usuarios: [],
            colunas_usuarios: [],

            info_status: {},

            dados_status: [],
            colunas_status: [],

            ehPersonalizado: false,
            membro_selecionado: '',
            query_range_1: "month",
            query_range_2: "month",
            query_periodo_fim: "",
            query_periodo_inicio: "",
            isDesktop: true
        };
    },
    mounted() {
        window.addEventListener("resize", this.updateIsDesktop);
        this.updateIsDesktop();
        this.getTeam();
        this.getRendimento();

    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateIsDesktop);
    },
    methods: {
        updateIsDesktop() {
            this.isDesktop = window.innerWidth >= 1024;


        },

        getRendimento() {
            this.getAtendimentosEquipe()
            this.getStatusEquipe()

        },

        async getAtendimentosEquipe() {
            this.esta_carregando = true
            const request = {
                intervalo: this.query_range_1
            };
            await axios
                .post("/api/v1/teams/get_atendimentos_equipe/", request)
                .then(response => {
                    // console.log(response.data)
                    this.dados_usuarios = response.data.membros_atribuidos.membros;
                    this.colunas_usuarios = response.data.membros_atribuidos.quantidade;
                    this.$refs.barChart.atualizarLabels(this.dados_usuarios);
                    this.$refs.barChart.atualizarData(this.colunas_usuarios);
                })
                .catch(error => {
                    console.log(error);
                });
            this.esta_carregando = false
        },
        async getStatusEquipe() {
            this.esta_carregando_pie = true
            const request = {
                intervalo: this.query_range_2,
                membro: this.membro_selecionado
            };
            await axios
                .post("/api/v1/teams/get_status_equipe/", request)
                .then(response => {
                    // console.log(response.data)
                    this.dados_status = response.data.status_contatos.status;
                    this.colunas_status = response.data.status_contatos.quantidade;
                    this.$refs.pieChart.atualizarDados(this.dados_status, this.colunas_status);

                })
                .catch(error => {
                    console.log(error);
                });


            for (let i = 0; i < this.dados_status.length; i++) {

                this.info_status[this.dados_status[i]] = this.colunas_status[i]

            }

            // console.log(this.info_status)
            this.esta_carregando_pie = false
        },

        async getTeam() {
            await axios
                .get("/api/v1/teams/get_my_team")
                .then(response => {
                    this.team = response.data;
                    // console.log(this.team)
                    // console.log(this.$store.state.user.username)
                })
                .catch(error => {
                    console.log(error);
                });
        },
        confirmarRemocao(id, username) {
            this.id_membro_deletar = id;
            // console.log(id)
            this.username_membro_deletar = username;
            this.remocao_confirmada = true;
        },
        async apagarMembro() {
            const request = { id: this.id_membro_deletar };
            await axios
                .post(`/api/v1/teams/remove_member/`, request, {
                    headers: {
                        "Access-Control-Allow-Origin": "http://127.0.0.1:8080",
                    }
                })
                .then(response => {
                    toast({
                        message: "O membro foi removido!",
                        type: "is-success",
                        dimissible: true,
                        pauseOnHover: true,
                        durations: 3000,
                        position: "top-right",
                    });
                })
                .catch(error => {
                    console.log(error);
                });

            this.remocao_confirmada = false;
            this.getTeam();
            this.getAtendimentosEquipe()
        },
        cancelarRemocao() {
            this.remocao_confirmada = false;
            this.username_membro_deletar = "";
            this.id_membro_deletar = 0;
        },
        ativarModal() {
            if (this.team.members.length < 5) {
                this.add_membro = !this.add_membro;
                this.email = "";
                this.password1 = "";
                this.password2 = "";
                this.username = "";
            }
            else {
                toast({
                    message: "A equipe atingiu o limite máximo de membros.",
                    type: "is-info",
                    dimissible: true,
                    pauseOnHover: true,
                    durations: 2000,
                    position: "top-right",
                    dismissible: true,
                    single: true,
                });
            }
        },
        async submitForm() {
            this.errors = [];
            if (this.username === "") {
                this.errors.push("- Usuário não informado");
            }
            //V
            if (this.password1 === "") {
                this.errors.push("- A senha é muito pequena");
            }
            //Verifica se as senhas estão batendo
            if (this.password1 !== this.password2) {
                this.errors.push("- As senhas não batem");
            }
            // Verifica se não foi encontrado nenhum erro
            if (!this.errors.length) {
                await axios
                    .post("/api/v1/validar_email/", { email: this.email })
                    .then(response => {
                        // console.log(response.data.code)
                        if (response.data.code === 200) {
                            const formData = {
                                username: this.username,
                                email: this.email,
                                firstname: this.first_name,
                                password: this.password1,
                            };
                            axios
                                .post("/api/v1/users/", formData, {})
                                .then(response => {
                                    toast({
                                        message: "Membro adicionado com sucesso!",
                                        type: "is-success",
                                        dimissible: true,
                                        pauseOnHover: true,
                                        durations: 2000,
                                        position: "top-right",
                                    });
                                    const emailData = { "email": this.username };
                                    axios
                                        .post("/api/v1/teams/add_member/", emailData, {
                                            headers: {
                                                "Access-Control-Allow-Origin": "http://127.0.0.1:8080",
                                            }
                                        })
                                        .then(response => {
                                            this.add_membro = false;
                                            this.getTeam();
                                            this.getAtendimentosEquipe();
                                        })
                                        .catch(error => {
                                            if (error.response) {
                                                for (const property in error.response.data)
                                                    this.errors.push(`${property}:${error.response.data[property]}`);
                                            }
                                            else if (error.message) {
                                                this.errors.push("Something went wrong. Please try again!");
                                            }
                                        });
                                })
                                .catch(error => {
                                    if (error.response) {
                                        for (const property in error.response.data)
                                            this.errors.push(`${error.response.data[property]}`);
                                    }
                                    else if (error.message) {
                                        this.errors.push("Something went wrong. Please try again!");
                                    }
                                });
                        }
                        else {
                            this.errors.push("Email já cadastrado");
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        getStatusColor(status) {

            if (status === "Novo") {
                return "hsl(207, 61%, 53%) !important"
                // return "info"
            } else if (status === "Em Aberto") {
                return "hsl(44, 100%, 77%) !important"
                // return "warning"
            } else if (status === "Finalizado") {
                return "hsl(348, 86%, 61%) !important"
                // return "danger"
            }
            return "hsl(153, 53%, 53%) !important"
            // return "success"
        },

    },
    components: { LineChartComponent, ChartComponent, ChartMembrosAtribuidos, PieChartMembrosAtribuidos }
}

</script>

<style>
.chart-view {
    max-height: 275px !important;

}

.chart-box {
    height: 450px;

}

.dot {

    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
}
</style>