<template>
    <div class="flexbox">
        <canvas height="280" ref="mychart2" id="mychart2"></canvas>
    </div>
</template>


<script>
import { Chart } from 'chart.js/auto'

export default {
    props: {
        colunas: Array,
        dados: Array
    },


    name: 'PieChartMembrosAtribuidos',
    mounted() {
        const ctx = this.$refs.mychart2


        this.mychart = new Chart(ctx, {
            type: 'pie',
            data: {
                labels: this.colunas,
                datasets: [{
                    label: 'Contatos',
                    data: this.dados,
                    borderWidth: 2,
                    backgroundColor: [
                        'hsl(207, 61%, 53%)',
                        'hsl(44, 100%, 77%)',
                        'hsl(348, 86%, 61%)',
                        'hsl(153, 53%, 53%)'
                    ],

                }]
            },
            options: {
                maintainAspectRatio: false,
                plugins: {
                    scales: {
                        x: {
                            beginAtZero: true
                        }
                    },
                    elements: {
                        point: {
                            radius: 0
                        }
                    },
                    legend: {
                        display: false,
                        align: 'center',
                        position: 'bottom'
                    },

                }

            }

        });

    },
    methods: {
        atualizarDados(labels, data) {

            this.mychart.data.labels = labels
            this.mychart.data.datasets[0].data = data


            this.mychart.update();
        }
    }
}


</script>

<style>
.flexbox {
    display: flex;

}
</style>