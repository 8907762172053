<template>
    <div class="container">
        <div class="columns is-multiline is-centered">

            <div class="column is-12">
                <div class="mb-3" style="display: flex; justify-content: center;">
                    <router-link :to="{ 'name': 'Dashboard' }">
                        <img src="../assets/logo.png">

                    </router-link>


                </div>

                <div class="column">
                    <div class="box">
                        <Terms />
                    </div>
                </div>

            </div>
        </div>


    </div>
</template>


<script>
import Terms from '@/components/Terms.vue';
import axios from 'axios';


export default {
    name: "TermosCondicoes",
    data() {
        return {
            termo_aceito: false,
            leu_tudo: false,
            errors: []
        };
    },
    mounted() {
        // console.log(this.$store.state.team.is_activated)
        if (this.$store.state.team.agreed_to_terms) {
            // console.log(this.$store.state.team.agreed_to_terms)
            this.$router.back()
        }
    },
    methods: {

        verificaScroll() {
            const termsBox = this.$refs.termsBox; // Obtenha a referência do elemento HTML dos termos e condições
            if (termsBox.scrollTop + termsBox.offsetHeight >= termsBox.scrollHeight) {
                // Verifique se o usuário chegou até o final do scroll
                // Faça alguma ação aqui, por exemplo, ativar um botão de aceitar os termos

                this.leu_tudo = true



            }

        },
    },
    components: { Terms }
}

</script>

<style>
.terms-box {
    height: auto;
}
</style>