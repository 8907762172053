<template>
    <nav class="navbar is-dark">
        <div class="navbar-brand">

            <template v-if="!this.$store.state.team.is_activated">
                <router-link :to="{ 'name': 'TermosCondicoes' }" class="navbar-item">
                    <img class="image" src="../../assets/reservai_logo.png">
                    <strong>ReservAI CRM</strong>
                </router-link>
            </template>
            <template v-else>
                <router-link :to="{ 'name': 'Dashboard' }" class="navbar-item">
                    <img class="image" src="../../assets/reservai_logo.png">
                    <strong>ReservAI CRM - {{ this.$store.state.team.name }}</strong>
                </router-link>
            </template>

        </div>

        <div class="navbar-menu is-active">
            <div class="navbar-end">
                <template v-if="this.$store.state.team.is_activated === true">
                    <router-link :to="{ 'name': 'Dashboard' }" class="navbar-item"><strong>Visão
                            Geral</strong></router-link>
                    <router-link :to="{ 'name': 'Contatos' }" class="navbar-item"><strong>Meus
                            Contatos</strong></router-link>
                    <router-link to="/dashboard/team" class="navbar-item"><strong>Meu Time</strong></router-link>
                </template>

                <template v-if="this.$store.state.isSuperUser === true">
                    <div class="navbar-item">
                        <router-link to="/dashboard/add-team" class="button is-success"><strong>Adicionar
                                Time</strong></router-link>

                    </div>
                </template>
                <div class="navbar-item">
                    <template v-if="this.$store.state.isAuthenticated">
                        <button class="button is-danger" @click="logout"><strong>Sair</strong></button>

                    </template>
                </div>


            </div>
        </div>

    </nav>
</template>

<script>
import axios from 'axios'
export default {
    name: 'Navbar',
    mounted() {
        //console.log("->" + this.$store.state.team.is_activated)
        // console.log('é superUser -> ', this.$store.state.isSuperUser)
        //console.log(this.$store.state.isSuperUser === true)
    },
    methods: {
        async logout() {

            await axios
                .post('/api/v1/token/logout/')
                .then(response => {
                    //console.log('Logged out')
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })

            axios.defaults.headers.common['Authorization'] = ''
            this.$store.commit('logoutUser')
            this.$store.commit('removeToken')

            this.$router.push('/log-in')

        }

    }
}

</script>

<style>
.navbar {
    background-color: #0c9d9c;
}
</style>