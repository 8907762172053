<template>
    <div class="container">
        <div class="columns is-multiline is-centered">
            <div class="column is-12">

                <div class="level-item">
                    <h1 class="title">Adicionar novo membro</h1>

                </div>
            </div>

            <div class="column is-5">
                <div class="box">
                    <form @submit.prevent="submitForm">

                        <div class="field">
                            <label>Usuário</label>
                            <div class="control">
                                <input type="text" name="username" class="input" v-model="username">
                            </div>
                            <label>Email</label>
                            <div class="control">
                                <input type="email" name="email" class="input" v-model="email">
                            </div>
                        </div>

                        <div class="field">
                            <label>Senha</label>
                            <div class="control">
                                <input type="password" name="password1" class="input" v-model="password1">
                            </div>
                        </div>

                        <div class="field">
                            <label>Repita sua Senha</label>
                            <div class="control">
                                <input type="password" name="password2" class="input" v-model="password2">
                            </div>
                        </div>

                        <div class="notification is-danger is-light" v-if="errors.length">
                            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                        </div>

                        <div class="field">
                            <div class="control">
                                <button class="button is-success">Cadastrar</button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import { toast } from 'bulma-toast'

export default {
    name: 'AddMember',
    data() {
        return {
            username: '',
            email: '',
            password1: '',
            password2: '',
            errors: []
        }
    },
    methods: {
        async submitForm() {
            this.errors = []
            if (this.username === '') {
                this.errors.push('- Usuário não informado')
            }

            //V
            if (this.password1 === '') {
                this.errors.push('- A senha é muito pequena')
            }

            //Verifica se as senhas estão batendo
            if (this.password1 !== this.password2) {
                this.errors.push('- As senhas não batem')
            }
            // Verifica se não foi encontrado nenhum erro
            if (!this.errors.length) {

                await axios
                    .post('/api/v1/validar_email/', { email: this.email })
                    .then(response => {
                        // console.log(response.data.code)
                        if (response.data.code === 200) {
                            const formData = {
                                username: this.username,
                                email: this.email,
                                firstname: this.first_name,
                                password: this.password1,
                            }

                            axios
                                .post('/api/v1/users/', formData, {
                                })
                                .then(response => {
                                    toast({
                                        message: 'Membro adicionado com sucesso',
                                        type: 'is-success',
                                        dimissible: true,
                                        pauseOnHover: true,
                                        durations: 2000,
                                        position: 'bottom-right',
                                    })


                                    const emailData = { 'email': this.username }

                                    axios
                                        .post('/api/v1/teams/add_member/', emailData, {
                                            headers: {
                                                'Access-Control-Allow-Origin': 'http://127.0.0.1:8080',
                                            }
                                        })
                                        .then(response => {
                                            this.$router.push({ 'name': 'Team' })
                                        })
                                        .catch(error => {
                                            if (error.response) {
                                                for (const property in error.response.data)
                                                    this.errors.push(`${property}:${error.response.data[property]}`)
                                            } else if (error.message) {
                                                this.errors.push('Something went wrong. Please try again!')
                                            }
                                        })

                                })
                                .catch(error => {
                                    if (error.response) {
                                        for (const property in error.response.data)
                                            this.errors.push(`${error.response.data[property]}`)
                                    } else if (error.message) {
                                        this.errors.push('Something went wrong. Please try again!')
                                    }
                                })

                        } else {
                            this.errors.push("Email já cadastrado")
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }
    }
}
</script>