<template>
  <div>

    <template v-if="$store.state.isAuthenticated && !$store.state.isLoading">
      <Navbar />
    </template>

    <template v-if="$store.state.isLoading">
      <div class="is-loading-bar has-text-centered" v-bind:class="{ 'is-loading': $store.state.isLoading }">

        <div id="carregamento">
          <div class="lds-dual-ring"></div>
          <br>
          <br>
          <span class="is-size-3">Carregando Contatos</span>
        </div>
      </div>
    </template>
    <section class="section">
      <router-view />
    </section>
  </div>
</template>


<script>
import Navbar from '@/components/layout/Navbar'
import axios from 'axios';


export default {
  name: 'App',
  components: {
    Navbar,
  },
  computed: {
    isLogin() {
      return this.$route.path === '/log-in';
    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')
    this.$store.commit('setIsLoading', false)
    // console.log('-> team name', this.$store.state.team.name, '\n-> team id ', this.$store.state.team.id)
    if (this.$store.state.token) {
      axios.defaults.headers.common['Authorization'] = "Token " + this.$store.state.token
    } else {
      axios.defaults.headers.common['Authorization'] = ''
    }

  },
  mounted() {
    // if (this.$store.state.isAuthenticated && !this.$store.state.team.is_activated) {
    //   axios.defaults.headers.common['Authorization'] = ''
    //   localStorage.removeItem('token')
    //   localStorage.removeItem('username')
    //   localStorage.removeItem('userid')
    //   localStorage.removeItem('team_name')
    //   localStorage.removeItem('team_id')
    //   localStorage.removeItem('isSuperUser')
    //   localStorage.removeItem('team_is_activated')
    //   localStorage.removeItem('team_agreed_to_terms')
    //   this.$router.push('/log-in/')
    // }

  }

}

</script>

<style lang="scss">
@import '../../node_modules/bulma';
@import '../../node_modules/vue3-easy-data-table/dist/style.css';
@import '../node_modules/@creativebulma/bulma-tooltip';


.background {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
}

.otherColor {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff; // whatever background color you want.
}


.lds-dual-ring {

  display: inline-block;
  width: 80px;
  height: 80px;
  z-index: 999999;
}

.lds-dual-ring:after {

  content: " ";
  display: block;
  width: 80px;
  height: 80px;
  margin: 0px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.is-loading-bar {

  position: absolute;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 99999;



  &.is-loading {
    background-color: #ffffff;

    height: 100vh;
    width: 100vw;

  }

}

#carregamento {
  position: relative;
  top: 40%;


}
</style>
