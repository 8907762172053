import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faFilter, faHouse, faArrowLeft, faPeopleRoof, faTrash, faFileExcel, faLock, faUser, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueMobileDetection from "vue-mobile-detection";

library.add(faSpinner, faLock, faUser, faFilter, faHouse, faArrowLeft, faPeopleRoof, faTrash, faFileExcel, faSearch)
axios.defaults.baseURL = 'https://api.reservai.app'

createApp(App).use(store).use(router, axios).use(VueMobileDetection).component('font-awesome-icon', FontAwesomeIcon).mount('#app')









