<template>
    <div class="flexbox">
        <canvas height="300" ref="mychart" id="myChart"></canvas>
    </div>
</template>

<script>
import { Chart } from 'chart.js/auto'
export default {
    name: 'LineChartComponent',
    data() {
        return {
            dados: [],
            colunas: ['Mínima', 'Média', 'Máxima'],

        }
    },
    mounted() {
        const ctx = this.$refs.mychart
        this.mychart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: this.colunas,
                datasets: [{
                    label: 'Curva',
                    backgroundColor: '#0c9d9c',
                    borderColor: 'rgba(12, 157, 156, 0.5)',
                    data: this.dados,
                    borderWidth: 5,
                    tension: 0.1
                }]
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    xAxis: {
                        beginAtZero: true,
                        display: false
                    }
                },
                plugins: {

                    legend: {
                        display: false,
                        position: 'bottom'
                    }
                }
            },

        });

    },
    methods: {
        atualizarLabels(labels) {

            this.mychart.data.labels = labels

            this.mychart.update();
        },
        atualizarData(data) {


            this.mychart.data.datasets[0].data = data

            this.mychart.update();
        }
    }
}


</script>

<style>
.flexbox {
    display: flex;

}
</style>