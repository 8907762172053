import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import HomeView from '../views/HomeView.vue'

import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import MyAccount from '../views/dashboard/MyAccount.vue'
import Leads from '../views/dashboard/Leads.vue'
import Contatos from '../views/dashboard/Contatos.vue'
import Contato from '../views/dashboard/Contato.vue'
import AddLead from '../views/dashboard/AddLead.vue'
import EditLead from '../views/dashboard/EditLead.vue'
import AddTeam from '../views/dashboard/AddTeam.vue'
import Team from '../views/dashboard/Team.vue'
import AddMember from '../views/dashboard/AddMember.vue'
import TermosCondicoes from '../views/TermosCondicoes.vue'
import Politica from '../views/Politica.vue'




const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requireLogin: true,
    }
  },
  // {
  //   path: '/sign-up',
  //   name: 'SignUp',
  //   component: SignUp
  // },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn,
    meta: {
      title: 'Log-in'
    }
  },
  {
    path: '/termos-condicoes',
    name: 'TermosCondicoes',
    component: TermosCondicoes,
    meta: {
      requireLogin: true,
      title: 'Termos de Uso'

    }
  },
  {
    path: '/politica',
    name: 'Politica',
    component: Politica,

  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireLogin: true,
      title: 'Visão geral'

    }
  },

  {
    path: '/dashboard/contatos',
    name: 'Contatos',
    component: Contatos,
    meta: {
      requireLogin: true,
      title: 'Meus Contatos'
    }
  },
  {
    path: '/dashboard/contatos/:id',
    name: 'Contato',
    component: Contato,
    meta: {
      requireLogin: true,
      title: 'Contato'
    }
  },

  {
    path: '/dashboard/add-team',
    name: 'AddTeam',
    component: AddTeam,
    meta: {
      requireLogin: true,
      title: 'Adicionar Time'
    }
  },
  {
    path: '/dashboard/team',
    name: 'Team',
    component: Team,
    meta: {
      requireLogin: true,
      title: 'Meu Time'
    }
  },
  {
    path: '/dashboard/add-member',
    name: 'AddMember',
    component: AddMember,
    meta: {
      requireLogin: true,
      title: 'Adicionar Membro'
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const title = to.meta.title
  if (title) {
    document.title = title
  }
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next('/log-in')

  } else {

    next()
  }


})
// router.beforeEach((to, from, next) => {
//   if (store.state.isAuthenticated && !store.state.team.is_activated) {
//     next('/termos-aceite')
//     console.log('aqui')
//   } else {
//     next()
//   }


// })

export default router
