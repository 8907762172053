<template>
    <div class="container">
        <div class="columns is-multiline is-centered is-vcentered">


            <div class="column is-6 ">
                <div class="box">
                    <div class="level-item">
                        <h1 class="title">Adicionar Time</h1>
                    </div>
                    <br>
                    <form @submit.prevent="submitForm">

                        <div class="field">
                            <label class="is-size-3">Nome do Time</label>
                            <div class="control">
                                <input type="text" class="input" required v-model="name">
                            </div>
                        </div>
                        <span class="is-size-3"> Moderador do time</span>
                        <div class="field">
                            <label>Usuário</label>
                            <div class="control">
                                <input type="text" name="username" required class="input" v-model="username">
                            </div>
                        </div>
                        <div class="field">
                            <label>Email</label>
                            <div class="control">
                                <input type="email" name="email" required class="input" v-model="email">
                            </div>
                        </div>

                        <div class="notification is-danger is-light" v-if="errors.length">
                            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                        </div>
                        <div class="field">
                            <div class="control">
                                <button class="button is-success">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: 'Team',
    data() {
        return {
            name: '',
            username: '',
            password1: '',
            password2: '',
            email: '',

            errors: []
        }

    },
    methods: {
        async submitForm() {
            // this.$store.commit('setIsLoading', true)
            this.errors = []
            if (this.username === '') {
                this.errors.push('- Usuário não informado')
            }

            //V
            // if (this.password1 === '') {
            //     this.errors.push('- A senha é muito pequena')
            // }

            //Verifica se as senhas estão batendo
            // if (this.password1 !== this.password2) {
            //     this.errors.push('- As senhas não batem')
            // }
            // Verifica se não foi encontrado nenhum erro
            if (!this.errors.length) {

                await axios
                    .post('/api/v1/validar_email/', { email: this.email })
                    .then(response => {
                        // console.log(response.data.code)
                        if (response.data.code === 200) {
                            const moderador = {

                                username: this.username,
                                password: "adminadmin",
                                email: this.email

                            }
                            axios


                                .post('/api/v1/users/', moderador, {
                                })
                                .then(response => {

                                    const time = {
                                        name: this.name,
                                        moderador: response.data.id
                                    }
                                    axios
                                        .post('api/v1/teams/add_team/', time)
                                        .then(response => {

                                            toast({

                                                message: 'O time foi adicionado com sucesso',
                                                type: 'is-success',
                                                dismissible: true,
                                                pauseOnHover: true,
                                                duration: 2000,
                                                position: 'xc-right',

                                            })

                                        })
                                        .catch(error => {

                                            console.log(error)

                                        })
                                })
                                .catch(error => {
                                    if (error.response) {
                                        for (const property in error.response.data)
                                            this.errors.push(`${error.response.data[property]}`)
                                    } else if (error.message) {
                                        this.errors.push('Something went wrong. Please try again!')
                                    }
                                })

                        } else {
                            toast({

                                message: 'Email já cadastrado',
                                type: 'is-warning',
                                dismissible: true,
                                pauseOnHover: true,
                                duration: 2000,
                                position: 'xc-right',

                            })
                        }
                    })
                    .catch(error => {

                    })

            }
        }
    }
}


</script>