<template>
    <div class="flexbox">
        <canvas height="300" width="250" ref="mychart" id="myChart"></canvas>
    </div>
</template>


<script>
import { Chart } from 'chart.js/auto'

export default {

    data() {
        return {
            dados: [],
            colunas: [],
            chart: {},

        }
    },

    name: 'ChartMembrosAtribuidos',
    mounted() {
        const ctx = this.$refs.mychart

        this.mychart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: this.colunas,
                datasets: [{
                    type: 'bar',
                    label: 'Contatos atribuídos',
                    stack: 'Num.',
                    borderColor: '#0c9d9c',
                    backgroundColor: "rgba(12, 157, 156, 0.5)",
                    data: this.dados,
                    borderWidth: 2,


                }],
            },

            options: {
                maintainAspectRatio: false,
                animation: true,
                scales: {
                    y: {
                        beginAtZero: true
                    },
                    x: {
                        beginAtZero: true
                    }
                },
                elements: {
                    point: {
                        radius: 0
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                        align: 'start',
                        position: 'bottom'
                    },

                    title: {
                        display: false,
                        text: 'Mês de interesse'
                    }
                }
            },

        });


    },
    methods: {
        atualizarLabels(labels) {

            this.mychart.data.labels = labels

            this.mychart.update();
        },
        atualizarData(data) {


            this.mychart.data.datasets[0].data = data

            this.mychart.update();
        }
    }
}


</script>

<style>
.flexbox {
    display: flex;

}
</style>