<template>
    <div class="container">
        <div class="columns is-multiline is-centered">

            <div class="column is-12">
                <div class="mb-3" style="display: flex; justify-content: center;">

                    <img src="../assets/logo.png">

                </div>

            </div>
            <div class="column is-7">
                <div class=" box terms-box">
                    <div class="box">
                        <article class="media">
                            <div class="media-content">
                                <div class="content is-scrollable" ref="termsBox" @scroll="verificaScroll">
                                    <Terms />
                                </div>
                            </div>
                        </article>
                    </div>
                    <form @submit.prevent="submitForm">
                        <div class="level">
                            <div class="level-left">
                                <div class="level-item">
                                    <div class="field">
                                        <div class="control">
                                            <label class="checkbox">
                                                <input type="checkbox" class="is-success" :disabled="!this.leu_tudo"
                                                    v-model='termo_aceito'>
                                                Li e concordo com o <strong>Termo de Uso</strong>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="level-right">
                                <div class="level-item">
                                    <div class="field is-centered">
                                        <div class="control">
                                            <button class="button is-success" :disabled="!this.termo_aceito"
                                                id="btn-entrar">Continuar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
            <div class="column is-12">
                <div class="level-item">
                    <img class="image" src="../assets/power2.png">
                </div>

            </div>

        </div>


    </div>
</template>

<script>
import Terms from '@/components/Terms.vue';
import axios from 'axios';


export default {
    name: "TermosCondicoes",
    data() {
        return {
            termo_aceito: false,
            leu_tudo: false,
            errors: []
        };
    },
    mounted() {
        // console.log(this.$store.state.team.is_activated)
        if (this.$store.state.team.agreed_to_terms) {
            // console.log(this.$store.state.team.agreed_to_terms)
            this.$router.back()
        }
    },
    methods: {
        async submitForm() {

            await axios.get("/api/v1/teams/update_agreed_to_terms/")
                .then(response => {

                    localStorage.setItem('team_is_activated', true)
                    localStorage.setItem('team_agreed_to_terms', true)
                    this.$store.state.team.is_activated = true
                    this.$store.state.team.agreed_to_terms = true

                    this.$store.commit('setIsLoading', true)
                    const request = { time: this.$store.state.team.name }
                    axios
                        .post('/api/v1/contatos/get_contatos_atualizados/', request)
                        .then(response => {

                            this.$store.commit('setIsLoading', false)
                            this.$router.push('/dashboard/')
                        })
                        .catch(error => {
                            console.log(error)
                        })
                })

                .catch(error => {
                    console.log(error)
                })


        },
        verificaScroll() {
            const termsBox = this.$refs.termsBox; // Obtenha a referência do elemento HTML dos termos e condições
            if (termsBox.scrollTop + termsBox.offsetHeight >= termsBox.scrollHeight) {
                // Verifique se o usuário chegou até o final do scroll
                // Faça alguma ação aqui, por exemplo, ativar um botão de aceitar os termos

                this.leu_tudo = true



            }

        },
    },
    components: { Terms }
}

</script>

<style>
@import '../assets/styles/login.scss'
</style>