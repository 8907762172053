<template>
    <div class="level-item">
        <span class="is-size-3">Termo de Uso do ReservAi</span>
    </div>
    <p><strong>Ao utilizar os serviços do ReservAi, você concorda com os termos e condições abaixo:</strong></p>

    <p><strong>API's oficiais:</strong> O ReservAi utiliza as API's oficiais do Instagram, Facebook e WhatsApp para oferecer
        os seus serviços.
        No entanto, não nos responsabilizamos por quaisquer problemas ou instabilidades decorrentes dessas plataformas.</p>


    <p><strong>Cookies:</strong> O ReservAi utiliza cookies para melhorar a experiência do usuário em nossos serviços. Ao
        utilizar nossos
        serviços, você concorda com o uso de cookies, os quais são essenciais para o correto funcionamento da plataforma.
    </p>

    <p><strong>Segurança da Informação:</strong> A proteção dos seus dados pessoais é uma prioridade para o ReservAi.
        Para garantir a
        segurança das suas informações, tomamos providências técnicas e administrativas adequadas para evitar perda, uso não
        autorizado ou outros abusos desses dados. Os dados são armazenados em um ambiente operacional seguro que não é
        acessível
        ao
        público. Adotamos as melhores práticas de segurança da informação disponíveis, incluindo o protocolo HTTPS no
        tráfego de
        dados pessoais via internet, gestão e controle de acesso ao banco de dados, criptografia de senhas, proteção contra
        acesso
        não autorizado a nossos sistemas e adoção de procedimentos preventivos contra incidentes de segurança da informação.
    </p>

    <p><strong>Atraso na mensalidade:</strong> Caso haja atraso no pagamento da mensalidade, o acesso à plataforma pode ser
        bloqueado e, caso o atraso seja superior a 60 dias, os dados poderão ser excluídos. Contudo, o ReservAi enviará
        notificações para o e-mail de cadastro do usuário antes da suspensão do acesso à plataforma ou exclusão dos dados.
    </p>

    <p><strong>Compartilhamento de informações:</strong> O ReservAi pode operar em conjunto com outras empresas em diversas
        atividades,
        incluindo hospedagem, armazenamento de dados e analytics. Dessa forma, nos reservamos o direito de compartilhar suas
        informações, incluindo dados pessoais, conforme os casos indicados abaixo. Adotaremos mecanismos de anonimização e
        pseudonimização desses dados e firmaremos cláusulas rigorosas em nossos contratos, visando preservar ao máximo a sua
        privacidade:
    <ul>
        <li>a. Com empresas parceiras para melhorar nossos serviços e garantir a qualidade de nossas operações;</li>
        <li>b. Com autoridades governamentais, nos casos previstos em lei;</li>
        <li>c. Com terceiros, caso seja necessário para proteger os nossos direitos, patrimônio ou segurança, ou de
            terceiros.</li>
    </ul>
    </p>
    <p><strong>Suspensão do serviço:</strong> O ReservAi pode interromper temporariamente o serviço para
        atualização ou manutenção a qualquer momento, sem aviso prévio. No entanto, nos esforçaremos para minimizar qualquer
        interrupção que possa causar
        inconvenientes aos usuários.
    </p>

    <p><strong>Atualizado em 20 de Abril de 2023</strong></p>
</template>
<script>
export default {
    name: 'Terms',
}
</script>
