<template>
    <div class="container" :class="{ 'is-fluid': this.isDesktop }">
        <div class="columns is-multiline is-mobile">

            <div class="column is-12 is-narrow">
                <div class="level-item box">
                    <h1 class="title is-1">Meus Contatos</h1>
                </div>
            </div>

            <div class="column is-12">


                <div class="box is-centered ">
                    <div class="columns is-centered is-multiline">
                        <div class="column ">
                            <div class="field">
                                <label class="label">Canal:</label>

                                <div class="control">
                                    <label class="radio">
                                        <input type="radio" name="canal" value="" v-model="query_canal"
                                            @click="selecionarCanal('')">
                                        Todos
                                    </label>
                                    <label class="radio">
                                        <input type="radio" name="canal" value="Instagram" v-model="query_canal"
                                            @click="selecionarCanal('Instagram')">
                                        Instagram

                                    </label>
                                    <label class="radio">
                                        <input type="radio" name="canal" value="Whatsapp" v-model="query_canal"
                                            @click="selecionarCanal('Whatsapp')">
                                        Whatsapp
                                    </label>
                                    <label class="radio">
                                        <input type="radio" name="canal" value="Facebook" v-model="query_canal"
                                            @click="selecionarCanal('Facebook')">
                                        Facebook
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="column ">

                            <label class="label">Período da Interação:</label>
                            <div class="field" :class="{ 'has-addons': this.isDesktop }">
                                <div class="control">
                                    <input @change="getContatos" type="date" class="input"
                                        v-model="query_interacao_inicial">
                                </div>

                                <div class="control" :class="{ 'pt-2': !this.isDesktop }">

                                    <input @change="getContatos" type="date" class="input" v-model="query_interacao_final">

                                </div>
                            </div>

                        </div>

                        <div class="column ">
                            <div class="field">
                                <label class="label">Mês de Interesse:</label>
                                <div class="control">
                                    <div class="select is-fullwidth">
                                        <select v-model="query_mes_interesse" @click="getContatos">

                                            <option value="" selected>---</option>
                                            <option value="1">Janeiro</option>
                                            <option value="2">Fevereiro</option>
                                            <option value="3">Março</option>
                                            <option value="4">Abril</option>
                                            <option value="5">Maio</option>
                                            <option value="6">Junho</option>
                                            <option value="7">Julho</option>
                                            <option value="8">Agosto</option>
                                            <option value="9">Setembro</option>
                                            <option value="10">Outubro</option>
                                            <option value="11">Novembro</option>
                                            <option value="12">Dezembro</option>

                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label"><strong>Status:</strong></label>
                                <div class="control">
                                    <div class="select is-fullwidth">
                                        <select v-model="query_status" @click="getContatos">
                                            <option value="" selected> --- </option>
                                            <option value="Novo"> Novo </option>
                                            <option value="Em Aberto"> Em Aberto </option>
                                            <option value="Reservado"> Reservado </option>
                                            <option value="Finalizado"> Finalizado </option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="columns is-centered  is-multiline">
                        <div class="column">
                            <div class="field">
                                <label class="label"><strong>Tags:</strong></label>
                                <div class="control ">
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="this.check_acomodacoes" @click="setAcomodacoes">
                                        Viu Acomodações
                                    </label>
                                    <div class="control">
                                        <label class="checkbox">
                                            <input type="checkbox" v-model="this.check_familia" @click="setFamilia">
                                            Família
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="column ">

                            <label class="label">Período da Consulta:</label>

                            <div class="field" :class="{ 'has-addons': this.isDesktop }">
                                <div class="control">
                                    <input type="date" @change="getContatos" class="input" v-model="query_consulta_inicial">
                                </div>

                                <div class="control " :class="{ 'pt-2': !this.isDesktop }">

                                    <input type="date" @change="getContatos" class="input" v-model="query_consulta_final">

                                </div>
                            </div>

                        </div>
                        <div class="column ">
                            <div class="field">
                                <label class="label">Atribuído para:</label>
                                <div class="control">
                                    <div class="select is-fullwidth">
                                        <select v-model="query_membro_atribuido" @click="getContatos">
                                            <option value="" selected> --- </option>
                                            <option v-for="member in this.team.members" v-bind:id="member.id"
                                                v-bind:value="member.username">
                                                {{ member.username }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="column ">
                            <div class="field">
                                <label class="label">Nome:</label>
                                <div class="control has-icons-right">
                                    <input type='text' class=" input is-maxlength-10 is-fullwidth" v-model="query"
                                        @change="getContatos">
                                    <span class="icon is-right"><font-awesome-icon icon="search" /></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <button class="button  is-inverted is-fullwidth" @click="limparFiltros">
                    <span class="icon">
                        <font-awesome-icon icon="trash" />
                    </span>
                    <span>Limpar Filtros</span>
                </button>
            </div>


            <div class="column is-12">
                <div class="box">

                    <nav class="columns is-multiline is-vcentered is-centered">
                        <div class="column" :class="{ 'is-12': !this.isDesktop }">
                            <h1 class="subtitle">
                                <span v-if="query != ' '"><strong>Resultado: {{ this.qtd_contatos }}
                                        Contatos
                                    </strong></span>
                                <span><strong> (Mostrando: {{ this.contatos.length }})</strong></span>
                            </h1>
                        </div>

                        <div class="column is-narrow" :class="{ 'is-12': !this.isDesktop }">

                            <h1 class="subtitle is-5">
                                <span><strong>
                                        {{
                                            this.contatos_selecionados.length }}
                                        Selecionado(s)
                                    </strong></span>
                            </h1>

                        </div>
                        <div class="column is-narrow" :class="{ 'is-12': !this.isDesktop }">
                            <div class="field" :class="{ 'has-addons': this.isDesktop }">
                                <div class="control has-text-centered">
                                    <div class="select" :class="{ 'is-fullwidth': !this.isDesktop }">
                                        <select v-model="membro_atribuido"
                                            :disabled="this.contatos_selecionados.length === 0">
                                            <option value="" selected>Selecionar Membro</option>
                                            <option v-for="member in this.team.members" v-bind:id="member.id"
                                                v-bind:value="member.id">
                                                {{ member.username }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control  has-text-centered" :class="{ 'pt-2': !this.isDesktop }">
                                    <button class=" button is-success" :class="{ 'is-fullwidth': !this.isDesktop }"
                                        :disabled="this.contatos_selecionados.length === 0"
                                        @click="atribuirMembro"><strong>Atribuir</strong></button>
                                </div>
                            </div>



                        </div>
                        <div class="column">
                            <!-- </template> -->
                            <div class="buttons is-right">
                                <button class='button is-success' @click="exportarTabela"
                                    :class="{ 'is-fullwidth': !this.isDesktop }">
                                    <span class="icon">
                                        <font-awesome-icon icon="file-excel" />
                                    </span>
                                    <span>Exportar Tabela</span>
                                </button>
                            </div>
                        </div>
                        <!-- <div class="column">
                            <button class="button is-danger is-responsive" @click="limparFiltros">
                                <span class="icon">
                                    <font-awesome-icon icon="trash" />
                                </span>
                                <span>Limpar Filtros</span>
                            </button>


                        </div> -->

                    </nav>

                    <br>
                    <div class="table-container">
                        <table class="table is-hoverable is-narrow is-fullwidth
                        " ref="exportable_table">
                            <thead class="is-sticky">

                                <tr>

                                    <th>
                                        <div>
                                            <label class="checkbox">
                                                <input type="checkbox" id="cb_all" @change="selecionaTodosContatos">
                                            </label>
                                        </div>
                                    </th>
                                    <th></th>
                                    <th class="name">Nome</th>
                                    <th class="has-text-centered">Status</th>
                                    <!-- <th class="has-text-centered">Canal</th> -->
                                    <th class="has-text-centered is-hidden-touch">
                                        <div class="control">
                                            <button style="padding-bottom: 10px;"
                                                :class="`button is-small ${this.whatsapp_estilo} is-inverted`"
                                                @click="setFiltroWhatsapp">
                                                <span
                                                    style="font-size:16px;font-weight:bold;font-family: Poppins;">Whatsapp</span>
                                                <font-awesome-icon icon="filter" />
                                            </button>
                                        </div>
                                    </th>
                                    <th class="is-hidden-touch">
                                        <div class="control">
                                            <button style="padding-bottom: 10px;"
                                                :class="`button is-small ${this.email_estilo} is-inverted`"
                                                @click="setFiltroEmail">
                                                <span
                                                    style="font-size:16px;font-weight:bold;font-family: Poppins;">Email</span>
                                                <font-awesome-icon icon="filter" />
                                            </button>
                                        </div>
                                    </th>

                                    <th class="has-text-centered is-hidden-touch">Última Interação</th>
                                    <th class="has-text-centered"><span class="pr-4"><strong>Tags</strong></span></th>

                                    <th class="has-text-centered is-hidden-touch">
                                        <div class=" control">
                                            <button style="padding-bottom: 10px;"
                                                :class="`button is-small ${this.consulta_estilo} is-inverted`"
                                                @click="setFiltroConsulta">
                                                <span
                                                    style="font-size:16px;font-weight:bold;font-family: Poppins;">Consulta</span>
                                                <font-awesome-icon icon="filter" />
                                            </button>
                                        </div>
                                    </th>
                                    <th class="has-text-centered is-hidden-touch">Interesse</th>
                                    <th class="has-text-centered is-hidden-touch"> Curva
                                    </th>
                                    <th class="has-text-centered">Atribuído</th>
                                    <!-- <th></th> -->

                                </tr>

                            </thead>

                            <tbody>

                                <tr v-for="  lead   in   contatos  " v-bind:key="lead.id">
                                    <td>
                                        <div>
                                            <label class="checkbox"
                                                :class="{ 'has-tooltip-danger has-tooltip-right': lead.membro_atribuido !== null }"
                                                :data-tooltip="lead.membro_atribuido !== null ? 'O contato já está atribuido.' : null">
                                                <input type="checkbox" :disabled="lead.membro_atribuido !== null"
                                                    name="selecionar" @change="addContatoSelecionado(lead.id)">
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="container">

                                            <figure class="image is-32x32">
                                                <img @error="imagemPadrao" class="is-rounded" :src="`${!lead.profile_url == '' ? lead.profile_url :
                                                        getImgPath('default')}`
                                                    ">

                                            </figure>


                                            <figure class="image is-16x16 canal">
                                                <img :alt="`${lead.canal}`" :src="getImgPath(lead.canal)"
                                                    class='is-rounded'>
                                            </figure>

                                        </div>
                                    </td>
                                    <td><router-link :to="{ name: 'Contato', params: { id: lead.id } }"><span>{{
                                        lead.nome }}</span></router-link></td>
                                    <!-- <td class="has-text-centered">
                                        <div class="select pr-2">
                                            <select>
                                                <option value="" selected>Pendente</option>
                                                <option value="-1">Em Atend.</option>
                                                <option value="-1">Concluído</option>
                                            </select>
                                        </div>
                                    </td> -->
                                    <td class="has-text-centered">
                                        <span :class="`tag is-${getStatusColor(lead.status)} is-normal is-rounded`">{{
                                            lead.status
                                        }}</span>
                                    </td>
                                    <td class="has-text-centered is-hidden-touch">
                                        <template v-if="lead.whatsapp != null">
                                            <a :href="`https://web.whatsapp.com/send?phone=${lead.whatsapp}`"
                                                target="_blank">
                                                <p class="is-dark">{{ lead.whatsapp
                                                }}</p>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <span><strong>-</strong></span>
                                        </template>
                                    </td>
                                    <td widht="auto" class="is-hidden-touch">{{ lead.email }}</td>
                                    <td class="has-text-centered is-hidden-touch">{{
                                        this.formataData(lead.ultima_interacao)
                                    }}</td>
                                    <td class="has-text-centered">
                                        <div class="columns">
                                            <template v-if=lead.viu_acomodacoes>
                                                <div class="column is-centered pr-1 pl-1">

                                                    <figure class="image is-24x24" data-tooltip="Viu acomodações">
                                                        <img :src="getImgPath('find')" alt="Viu acomodações">
                                                    </figure>

                                                </div>
                                            </template>

                                            <template v-if=lead.criancas>
                                                <div class="column pl-1 pr-1">

                                                    <figure class="image is-24x24 " data-tooltip="Família">
                                                        <img :src="getImgPath('family')" alt="Família">
                                                    </figure>

                                                </div>
                                            </template>

                                        </div>
                                        <!-- {{ lead.viu_acomodacoes ? "Sim" : "Não" }} {{
                                            lead.criancas
                                            ? "Sim" : "Não" }} -->
                                    </td>
                                    <td class="has-text-centered is-hidden-touch">{{
                                        this.formataData(lead.data_consulta) }}
                                    </td>
                                    <td class="has-text-centered is-hidden-touch">{{ this.formataData(lead.data_entrada)
                                    }}
                                    </td>
                                    <td class="has-text-centered is-hidden-touch ">{{
                                        this.calcularCurva(lead.data_consulta,
                                            lead.data_entrada)
                                    }} {{ this.calcularCurva(lead.data_consulta, lead.data_entrada) > 1 ? "Meses" :
    this.calcularCurva(lead.data_consulta, lead.data_entrada) !== "-" &&
        this.calcularCurva(lead.data_consulta, lead.data_entrada) !== 0 ? "Mês" : "" }}
                                    </td>
                                    <td class="has-text-centered">
                                        {{ lead.membro_atribuido == null ? '-' :
                                            lead.membro_atribuido.username }}

                                    </td>
                                    <!-- <td>
                                        <router-link :to="{name: 'Lead', params: { id: lead.id}}">Details</router-link>
                                    </td> -->
                                </tr>

                            </tbody>

                        </table>

                    </div>
                    <div class="column is-12">


                        <div class="level-right">
                            <div class="buttons">
                                <button class="button is-dark" @click="goToPreviousPage()" v-if="showPrevious"
                                    href="#top"><strong>&lt</strong></button>
                                <button class="button is-dark" @click="goToNextPage()"
                                    v-if="showNext"><strong>></strong></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import axios from 'axios'
import { toast } from 'bulma-toast'
import { utils, writeFile } from 'xlsx'

export default {
    name: "Contatos",
    data() {
        return {

            membro_atribuido: '',
            status_checkboxes: false,
            contatos_selecionados: [],
            selecao: false,
            team: {
                members: []
            },
            contatos: [],
            contatos_exportados: [],
            qtd_contatos: 0,
            ultima_atualizacao: '',
            showNext: false,
            showPrevious: false,
            currentPage: 1,
            page_size: 50,
            query: '',
            query_canal: '',
            query_familia: '',
            check_familia: false,
            familia_estilo: 'is-dark',
            query_acomodacoes: '',
            check_acomodacoes: false,
            acomodacoes_estilo: 'is-dark',
            query_mes_interesse: '',
            query_membro_atribuido: '',
            query_status: '',

            query_whatsapp: '',
            whatsapp_estilo: 'is-dark',

            query_email: '',
            email_estilo: 'is-dark',

            query_consulta: '',
            consulta_estilo: 'is-dark',
            query_consulta_inicial: '',
            query_consulta_final: '',
            query_interacao_inicial: '',
            query_interacao_final: '',

            isDesktop: '',

        }
    },
    mounted() {
        window.addEventListener('resize', this.updateIsDesktop)
        this.updateIsDesktop();

        this.getContatos()
        this.getTime()
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateIsDesktop)
    },
    methods: {
        updateIsDesktop() {
            this.isDesktop = window.innerWidth >= 1024

        },
        async atribuirMembro() {

            if (this.membro_atribuido !== '') {


                const data = {
                    id_membro: this.membro_atribuido,
                    contatos: this.contatos_selecionados
                }
                await axios
                    .post('/api/v1/contatos/atribuir_membro_contatos/', data)
                    .then(response => {

                        document.getElementById("cb_all").checked = false
                        this.getContatos()
                        if (response.data) {
                            toast({
                                message: `${this.contatos_selecionados.length} contato(s) atribuido(s) ao membro ${response.data} \n`,
                                type: 'is-info',
                                dimissible: true,
                                pauseOnHover: true,
                                durations: 2000,
                                position: 'top-center',
                            })
                        }
                        document.getElementsByName("selecionar").forEach(element => {
                            if (element.checked) {
                                element.click()
                            }
                            this.status_checkboxes = false
                        })

                        this.membro_atribuido = ""
                    })
                    .catch(error => {
                        console.log(error)

                    })
            }
            else {
                toast({
                    message: 'Selecione um membro da equipe \n',
                    type: 'is-danger',
                    dimissible: true,
                    pauseOnHover: true,
                    durations: 2000,
                    position: 'top-right',
                })
            }
        },

        selecionaTodosContatos() {


            if (this.status_checkboxes === false) {
                document.getElementsByName("selecionar").forEach(element => {
                    if (!element.checked) {
                        element.click()
                    }

                    this.status_checkboxes = true
                });
            } else {
                document.getElementsByName("selecionar").forEach(element => {
                    if (element.checked) {
                        element.click()
                    }
                    this.status_checkboxes = false
                });
            }

            // for (var i = 0; i < this.contatos.length; i++) {
            //     document.getElementById("cb" + i).value = true
            //     console.log("cb" + i)
            //     console.log(document.getElementById("cb" + i))
            // }
        },
        addContatoSelecionado(idContato) {

            if (!this.contatos_selecionados.find(element => element === idContato)) {
                this.contatos_selecionados.push(idContato)

            } else {
                this.contatos_selecionados.splice(this.contatos_selecionados.indexOf(idContato), 1)
            }
            // console.log(this.contatos_selecionados)

        },

        imagemPadrao(e) {
            e.target.src = this.getImgPath('default')
        },

        submitForm() {
            this.getContatos()
        },

        goToPreviousPage() {
            this.currentPage -= 1
            this.getContatos()
            window.scrollTo(0, 0);
        },
        goToNextPage() {
            this.currentPage += 1
            this.getContatos()
            window.scrollTo(0, 0);
        },
        selecionarCanal(string) {
            this.query_canal = string
            this.currentPage = 1
            this.getContatos()
        },
        setFamilia() {
            if (this.query_familia === '') {
                this.query_familia = true
                this.familia_estilo = 'is-success'
            }
            else if (this.query_familia == true) {
                this.query_familia = ''
                this.familia_estilo = 'is-danger'
            }
            // else if (this.query_familia == false) {
            //     this.query_familia = ''
            //     this.familia_estilo = 'is-dark'

            // }

            this.currentPage = 1
            this.getContatos()
        },
        setAcomodacoes() {
            if (this.query_acomodacoes === '') {
                this.query_acomodacoes = true
                this.acomodacoes_estilo = 'is-success'
            }
            else if (this.query_acomodacoes == true) {
                this.query_acomodacoes = ''
                this.acomodacoes_estilo = 'is-danger'
            }
            // else if (this.query_acomodacoes == false) {
            //     this.query_acomodacoes = ''
            //     this.acomodacoes_estilo = 'is-dark'
            // }

            this.currentPage = 1
            this.getContatos()
        },
        setFiltroWhatsapp() {
            if (this.query_whatsapp === '') {
                this.query_whatsapp = false
                this.whatsapp_estilo = 'is-success'
            }
            else if (this.query_whatsapp == false) {
                this.query_whatsapp = ''
                this.whatsapp_estilo = 'is-dark'
            }

            this.currentPage = 1
            this.getContatos()
        },
        setFiltroEmail() {
            if (this.query_email === '') {
                this.query_email = false
                // console.log('Aqui ->' + this.query_email)
                this.email_estilo = 'is-success'
            }
            else if (this.query_email == false) {
                this.query_email = ''
                this.email_estilo = 'is-dark'
            }

            this.currentPage = 1
            this.getContatos()
        },
        setFiltroConsulta() {
            if (this.query_consulta === '') {
                this.query_consulta = false
                this.consulta_estilo = 'is-success'
            }
            else if (this.query_consulta == false) {
                this.query_consulta = ''
                this.consulta_estilo = 'is-dark'
            }

            this.currentPage = 1
            this.getContatos()
        },
        async getTime() {

            await axios
                .get('/api/v1/teams/get_my_team')
                .then(response => {
                    this.team = response.data
                    // console.log(this.team)
                    // console.log(this.$store.state.user.username)
                })
                .catch(error => {
                    console.log(error)

                })

        },
        async getContatos() {
            // this.$store.commit('setIsLoading', true)
            this.var_i = 0
            this.showNext = false
            this.showPrevious = false

            const filtro = {
                p: this.currentPage,
                count: 50,
                nome: this.query,
                canal: this.query_canal,
                criancas: this.query_familia,
                viu_acomodacoes: this.query_acomodacoes,
                mes_interesse: this.query_mes_interesse,
                whatsapp: this.query_whatsapp,
                email: this.query_email,
                consulta: this.query_consulta,
                data_consulta_after: this.query_consulta_inicial,
                data_consulta_before: this.query_consulta_final,
                ultima_interacao_after: this.query_interacao_inicial,
                ultima_interacao_before: this.query_interacao_final,
                membro_atribuido__username: this.query_membro_atribuido,
                status: this.query_status
            };

            await axios

                .get(`/api/v1/contatos/`, { params: filtro })
                .then(response => {
                    this.contatos = response.data.results
                    this.qtd_contatos = response.data.count

                    if (response.data.next) {
                        this.showNext = true
                    }

                    if (response.data.previous) {
                        this.showPrevious = true
                    }

                })
                .catch(error => {
                    console.log(error.response.status)

                    if (error.response.status === 401) {
                        axios.defaults.headers.common['Authorization'] = ""
                        this.$store.commit('logoutUser')
                        this.$store.commit('removeToken')
                        this.$router.push('/log-in')
                        toast({
                            message: 'Sessão expirada!\n',
                            type: 'is-danger is-light',
                            dimissible: true,
                            pauseOnHover: true,
                            durations: 2000,
                            position: 'top-center',
                        })


                    }

                })

            // this.$store.commit('setIsLoading', false)


        },
        async deletarContatos() {
            this.$store.commit('setIsLoading', true)

            axios
                .delete('/api/v1/contatos/apaga_todos_contatos/')
                .then(response => {
                    this.contatos = []

                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)

        },

        async atualizarContatos() {
            this.$store.commit('setIsLoading', true)

            const request = { last_update: this.$store.state.lastUpdate, time: this.$store.state.team.name, }

            await axios
                .post('/api/v1/contatos/get_contatos_atualizados/', request)
                .then(response => {

                    localStorage.setItem("lastUpdate", response.data['last_update'])
                    this.$store.commit('setLastUpdate', response.data['last_update'])

                    if (!response.data["atualizado"]) {
                        toast({
                            message: 'A lista já está atualizada',
                            type: 'is-success',
                            dimissible: true,
                            pauseOnHover: true,
                            durations: 2000,
                            position: 'bottom-right',
                        })
                    } else {

                        this.getContatos()
                        const data = new Date(this.$store.state.lastUpdate)

                        this.ultima_atualizacao = data.toLocaleString('pt-br')
                    }

                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)

        },

        extrairMes(data) {

            const data_completa = new Date(data)
            const mes = data_completa.toLocaleString("pt-BR", { month: "long" })
            const retorno = data_completa.getDate() + "/" + mes.split(" ")[0].charAt(0).toUpperCase() + mes.slice(1)

            if (retorno === "NaN/Invalid Date") {
                return "-"
            }

            return retorno

        },
        formataData(data) {

            if (data === null) {
                return '-'
            }

            data = data.split('-')


            return `${data[2]}/${data[1]}/${data[0]}`
        },

        calcularCurva(data1, data2) {

            const data_consulta = new Date(data1)
            const data_interesse = new Date(data2)

            if (!data1) {
                return "-"
            }
            const curva = (data_interesse.getMonth() - data_consulta.getMonth())
            if (curva < 0) {
                return curva + 12
            }
            if (curva === 1) {
                return curva
            }
            return curva
        },

        getImgPath(nome) {
            return require(`../../assets/${nome}.png`)
        },

        async exportarTabela(e) {
            const table = this.$refs.exportable_table;
            const data = [];

            await axios
                .get(`/api/v1/contatos/?p=1&count=${this.qtd_contatos}&nome=${this.query}&canal=${this.query_canal}&criancas=${this.query_familia}&viu_acomodacoes=${this.query_acomodacoes}&mes_interesse=${this.query_mes_interesse}&whatsapp=${this.query_whatsapp}&email=${this.query_email}&data_consulta_after=${this.query_consulta_inicial}&data_consulta_before=${this.query_consulta_final}&consulta=${this.query_consulta}&ultima_interacao_after=${this.query_interacao_inicial}&ultima_interacao_before=${this.query_interacao_final}`)
                .then(response => {

                    this.contatos_exportados = response.data.results
                })
                .catch(error => {
                    console.log(error)
                })

            // Obter os dados do array de objetos
            const arrayContatos = Array.from(this.contatos_exportados)
            arrayContatos.forEach(obj => {
                const rowValues = {
                    Nome: obj.nome,
                    Whatsapp: obj.whatsapp,
                    Email: obj.email
                }
                data.push(rowValues);
            });

            // const worksheet = utils.table_to_sheet(table, { header:["Nome","Email"],skipHeader:true },);
            const worksheet = utils.json_to_sheet(data, { header: ['Nome', 'Whatsapp', 'Email'] })
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            writeFile(workbook, `relatorio_${this.$store.state.team.name}.xlsx`);

        },

        limparFiltros() {

            this.query_membro_atribuido = ''
            this.query_consulta_inicial = ''
            this.query_consulta_final = ''
            this.query_interacao_inicial = ''
            this.query_interacao_final = ''
            this.query_mes_interesse = ''
            this.query_canal = ''
            this.query_acomodacoes = ''
            this.acomodacoes_estilo = 'is-dark'
            this.query_whatsapp = ''
            this.whatsapp_estilo = 'is-dark'
            this.query_familia = ''
            this.familia_estilo = 'is-dark'
            this.query_consulta = ''
            this.consulta_estilo = 'is-dark'
            this.query_email = ''
            this.email_estilo = 'is-dark'
            this.check_familia = false
            this.check_acomodacoes = false
            this.query_status = ''
            this.query = ''

            this.getContatos()

        },
        getStatusColor(status) {

            if (status === "Novo") {
                // return "hsl(207, 61%, 53%) !important"
                return "info"
            } else if (status === "Em Aberto") {
                // return "hsl(44, 100%, 77%) !important"
                return "warning"
            } else if (status === "Finalizado") {
                // return "hsl(348, 86%, 61%) !important"
                return "danger"
            }

            // return "hsl(153, 53%, 53%) !important"
            return "success"


        }



    }
}

</script>


<style>
.t {
    box-shadow: 10px 8px 7px rgba(0, 0, 0, 0.20000000298023224);
    background-color: rgba(255, 255, 255, 1);
    width: auto;
    height: auto;
    min-height: 400px;
    padding: 20px;
    top: 50%;
    /* Posiciona o texto no centro verticalmente */
    left: 50%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    z-index: 1;
}

.table.is-narrow td,
.table.is-narrow th {
    padding: 1em 0.5em;
    vertical-align: middle;

}

.name {
    width: 260px;
}

tr {
    vertical-align: middle;
}

th {
    font-family: Poppins;
}

td {
    font-weight: 600;
    vertical-align: middle;
}

a {
    color: #363636;
}

a:hover {
    color: green;
}

status {
    height: 5px;
}

.canal {

    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 99;
    border-radius: 50%;
    border: 1px solid #ffffff;
}
</style>

